import React from 'react';
import './styles.css';

export default function PrimaryButton(props) {
    const {title, className, type, isLoading, children, onClick} = props;
    const cssClass = ['primary-button', className || '', type].join(' ');

    return (
        <div className={cssClass} onClick={isLoading ? null : onClick}>
            {isLoading ? <div className="primary-button-loader" /> : title || children}
        </div>
    );
}
