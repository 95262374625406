import {useQuery} from '@tanstack/react-query';
import EightifyAPI from '../../api/api';

export const PRICING_INFO_QUERY_KEY = ['pricingInfo'];

export const usePricingInfo = () => {
    return useQuery({
        queryKey: PRICING_INFO_QUERY_KEY,
        staleTime: 5 * 60 * 1000,
        queryFn: () => EightifyAPI.getPricingInfo(),
    });
};
