import {useEffect, useState} from 'react';

export const useSummaryFadingState = ({isPartial}) => {
    const [fadingState, setFadingState] = useState(null);

    useEffect(() => {
        const containerEl = document.getElementById('layout-content');

        if (!containerEl) return;

        if (isPartial) {
            setFadingState('bottom');
            return;
        }

        const onScroll = () => {
            const isScrolledFromTop = containerEl.scrollTop > 0;
            const isScrolledFromBottom =
                containerEl.scrollHeight - containerEl.scrollTop - containerEl.clientHeight > 0;

            if (isScrolledFromTop && isScrolledFromBottom) {
                setFadingState('both');
            } else if (isScrolledFromTop) {
                setFadingState('top');
            } else if (isScrolledFromBottom) {
                setFadingState('bottom');
            } else {
                setFadingState(null);
            }
        };

        containerEl.addEventListener('scroll', onScroll, {passive: true});
        const interval = setInterval(onScroll, 100);

        return () => {
            containerEl.removeEventListener('scroll', onScroll);
            clearInterval(interval);
        };
    }, [isPartial]);

    return fadingState;
};
