import Tippy from "@tippyjs/react";
import {useState} from "react";

export default function TippyTooltip(props) {
    const {placement, content, disabled, children} = props
    const [active, setActive] = useState(false)

    return (
        <Tippy
            placement={placement} content={content} delay={active ? 300 : 1000}
            onShown={() => setActive(true)}
            onHidden={() => setActive(false)}
            disabled={disabled}
        >
            {children}
        </Tippy>

    );
}
