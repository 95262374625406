import {createContext, PropsWithChildren, useState} from 'react';

type AuthContextType = {
    isAuthorized: boolean | null;
    setIsAuthorized: (value: boolean | null) => void;
};

export const AuthContext = createContext<AuthContextType>({
    isAuthorized: false,
    setIsAuthorized: () => {},
});

export const AuthContextProvider = ({children}: PropsWithChildren) => {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

    return <AuthContext.Provider value={{isAuthorized, setIsAuthorized}}>{children}</AuthContext.Provider>;
};
