export default function TikTokIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none">
            <path
                fill="currentColor"
                d="M15 6.563c-1.406.03-2.719-.407-3.844-1.22v5.595c0 2.124-1.312 4-3.312 4.75A5.04 5.04 0 0 1 2.25 14.28 5.118 5.118 0 0 1 1.594 8.5c1-1.844 3.062-2.875 5.187-2.594V8.72a2.36 2.36 0 0 0-2.625.844c-.562.843-.562 1.937.032 2.75a2.329 2.329 0 0 0 2.593.843 2.345 2.345 0 0 0 1.625-2.219V0h2.75c0 .25 0 .469.063.719a3.695 3.695 0 0 0 1.687 2.5A3.748 3.748 0 0 0 15 3.844v2.719Z"
            />
        </svg>
    );
}
