import {Fragment, useEffect, useState} from 'react';
import cn from 'classnames';
import MainMenu from './MainMenu';
import {ChevronUpIcon} from '../../../components/icons/ChevronUpIcon';
import {BarsIcon} from '../../../components/icons/BarsIcon';
import {useFrameHeight} from '../../../hooks/ui/useFrameHeight';

export default function MenuButton() {
    const [isOpen, setIsOpen] = useState(false);
    const {requestContentHeight} = useFrameHeight();
    const mainMenuId = 'main-menu';

    useEffect(() => {
        if (isOpen) {
            const menuElement = document.getElementById(mainMenuId);
            const menuHeight = menuElement?.offsetHeight ?? 0;

            requestContentHeight(menuHeight + 32);
        } else {
            requestContentHeight(0);
        }
    }, [isOpen]);

    return (
        <div className="menu-button-wrapper">
            <div
                className={cn('menu-button', {'menu-button--close': isOpen})}
                onClick={() => setIsOpen((value) => !value)}
            >
                {isOpen ? <ChevronUpIcon /> : <BarsIcon />}
            </div>

            {isOpen && (
                <Fragment>
                    <div className="menu-layout">
                        <MainMenu id={mainMenuId} setIsOpened={setIsOpen} />
                    </div>

                    <div className="menu-layout-background" onClick={() => setIsOpen(false)} />
                </Fragment>
            )}
        </div>
    );
}
