import React, {Fragment, useContext, useMemo} from 'react';
import moment from 'moment';
import PageTitle from '../../../components/PageTitle';
import UnlimitedSubscriptionPlan from './components/UnlimitedSubscriptionPlan';
import './styles.css';
import Const from '../../../const';
import CheckoutButton from './components/CheckoutButton';
import SubscriptionPricesList from './components/SubscriptionPricesList';
import logoPro from '../../images/logo-pro.svg';
import infinity from '../../images/infinity.svg';
import clock from '../../images/clock.svg';
import earth from '../../images/earth.svg';
import comments from '../../images/comments.svg';
import PageLoader from '../../../components/PageLoader';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import {useProfile} from '../../../contexts/profile/useProfile';

export default function SubscriptionsSubpage(props) {
    const {balance, prices, showSwitch, showTrial, refillInfo, takeAllFromIt} = props;
    const {data: profileData, isLoading: isLoadingProfile} = useProfile({
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchInterval: 1000 * 15,
    });
    const {isSafari} = useContext(ThemeContext);
    const {activeSubscription, subscription} = profileData ?? {};

    const activeSubscriptionDescription = useMemo(() => {
        const activeSubscriptionPrice = prices.find((price) => price.name === activeSubscription?.name);

        if (!activeSubscriptionPrice) {
            return null;
        }
        const endDate = moment(activeSubscription.expiresAt).format('MMM D, YYYY');
        if (activeSubscription?.cancelAt) {
            return `Will expire on ${endDate}`;
        }

        const priceUSD = '$' + activeSubscriptionPrice.price.toFixed(2);

        return `Next billing of ${priceUSD} on ${endDate}`;
    }, [showSwitch, activeSubscription]);

    const manageSubscriptionUrl = useMemo(() => {
        if (!subscription) return null;

        return isSafari ? Const.manageSafariSubscriptionUrl : subscription.customerPortalUrl;
    }, [subscription, isSafari]);

    if (isLoadingProfile) {
        return <PageLoader />;
    }

    const noFreeSummaries = !activeSubscription && typeof balance === 'number' && balance <= 0;

    let inactiveSubscriptionTitle;
    let showLogo = false;
    let inactiveSubscriptionDescription = null;

    if (takeAllFromIt || !noFreeSummaries) {
        showLogo = true;
        inactiveSubscriptionTitle = 'Take all from it';
    } else {
        inactiveSubscriptionTitle = `You’re out of shots`;
        inactiveSubscriptionDescription = `Wait ${
            refillInfo?.formattedTimeToRefill ?? '...'
        } to recharge, or upgrade to shake off limits`;
    }

    let SubscriptionView;

    if (!prices) {
        SubscriptionView = null;
    } else if (isSafari) {
        if (activeSubscription) {
            SubscriptionView = () => (
                <Fragment>
                    <UnlimitedSubscriptionPlan
                        title={activeSubscription.name}
                        price={null}
                        isActive={true}
                        description={`Next payment on ${moment(activeSubscription.expiresAt).format('MMM D, YYYY')}`}
                        highlightedDescription={null}
                        hoverEnabled={false}
                        onClick={undefined}
                    />
                    <div style={{height: 4}} />
                </Fragment>
            );
        } else {
            SubscriptionView = () => (
                <Fragment>
                    <CheckoutButton title="Go to Eightify app" hasActiveSubscription={!!activeSubscription} />
                    <div className="go-to-app">You can get Eightify Pro in the Eightify app</div>
                </Fragment>
            );
        }
    } else {
        SubscriptionView = () => (
            <SubscriptionPricesList prices={prices} activeSubscription={activeSubscription} showTrial={showTrial} />
        );
    }

    return (
        <div className="subscription-container">
            {activeSubscription ? (
                <PageTitle
                    logo={<img src={logoPro} alt="Eightify Pro" />}
                    title={`${activeSubscription.name} plan is active`}
                    description={activeSubscriptionDescription}
                />
            ) : (
                <PageTitle
                    logo={showLogo && <img src={logoPro} alt="Eightify Pro" />}
                    title={inactiveSubscriptionTitle}
                    description={inactiveSubscriptionDescription}
                />
            )}

            <div className="subscription-property">
                <span className="property-icon">
                    <img src={infinity} alt="Infinity icon" />
                </span>{' '}
                Unlimited summaries
            </div>

            <div className="subscription-property">
                <span className="property-icon">
                    <img src={clock} alt="Clock icon" />
                </span>{' '}
                Any video length
            </div>

            <div className="subscription-property">
                <span className="property-icon">
                    <img src={earth} alt="Earth icon" />
                </span>{' '}
                Summary translations
            </div>

            <div className="subscription-property">
                <span className="property-icon">
                    <img src={comments} alt="Comments icon" />
                </span>{' '}
                Summary of comments
            </div>

            <div style={{height: 16}} />

            <SubscriptionView />

            {subscription && (
                <div className="manage-subscription">
                    <a target="_blank" href={'mailto:hello@eightify.app?subject=Subscription'}>
                        Contact support
                    </a>
                    {' • '}
                    <a target="_blank" href={manageSubscriptionUrl || 'mailto:hello@eightify.app?subject=Subscription'}>
                        Manage subscription
                    </a>
                </div>
            )}
            <div style={{height: 12}} />
        </div>
    );
}
