import './styles.css';
import Loader from '../Loader';

export default function PageLoader() {
    return (
        <div className="page-loader">
            <Loader size={40} />
        </div>
    );
}
