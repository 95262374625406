import {useEffect} from 'react';
import {ThemeContext} from './ThemeContext';
import {Variant} from "../../util/Variant";
import {Theme} from "../../util/Theme";

export default function ThemeContextProvider({children}) {
    const isSafari = global.isSafari;
    const {theme, variant} = global.eightify;
    useEffect(() => {
        document.body.classList.remove(
            ...Object.values(Variant).map(theme => `theme-${theme}`),
            ...Object.values(Theme).map(variant => `ui-${variant}`)
        )
        document.body.classList.add(`theme-${theme}`, `ui-${variant}`);
    });

    return (
        <ThemeContext.Provider
            value={{
                isSafari,
                theme,
                variant,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}
