import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import EightifyAPI, {NotAuthorizedError} from '../../api/api';
import {saveToStorage} from '../../util/util';
import {ProfileContext} from '../../contexts/profile/ProfileContext';
import {useUnauthorizedEvent} from '../../unauthorized/useUnauthorizedEvent';

export const useEightifyApi = (params) => {
    const {requestSelector, deps = [], autoLoad} = params;

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const {setIsAuthorized} = useContext(ProfileContext);
    const sendUnauthorizedEvent = useUnauthorizedEvent();

    const navigate = useNavigate();

    const performRequest = useCallback(() => {
        setIsLoading(true);
        return requestSelector(EightifyAPI)
            .then((data) => {
                setData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err instanceof NotAuthorizedError || err.message === 'No auth headers') {
                    saveToStorage('token', null);
                    setIsAuthorized(false);
                    sendUnauthorizedEvent({isAuto: true});

                    navigate('/unauthorized');
                    return;
                }

                setError(err);
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useEffect(() => {
        if (autoLoad) {
            performRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performRequest]);

    return {performRequest, data, isLoading, error};
};
