import React, {Fragment, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import './styles.css';
import {CrossIcon} from '../icons/CrossIcon';
import {Divider} from '../Divider';

export default function PageTitle(props) {
    const {logo, title, description, divider, hideCloseButton} = props;
    const navigate = useNavigate();

    const goToHome = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <Fragment>
            <div className="page-title__container">
                <div className="page-title__header">
                    {!!logo && <div className="page-title__logo">{logo}</div>}
                    <div className="page-title__title">{typeof title === 'string' ? <span>{title}</span> : title}</div>

                    {!!description && (
                        <div className="page-title__description">
                            {typeof description === 'string' ? <span>{description}</span> : description}
                        </div>
                    )}
                </div>

                {!hideCloseButton && (
                    <div className="page-title__cross-icon" onClick={goToHome}>
                        <CrossIcon />
                    </div>
                )}
            </div>

            {divider && <Divider className="page-title__divider" />}
        </Fragment>
    );
}
