export default function FriendIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="none">
            <path
                fill="currentColor"
                d="M14.5 9c0-2.313-1.25-4.438-3.25-5.625a6.524 6.524 0 0 0-6.5 0C2.719 4.563 1.5 6.688 1.5 9a6.507 6.507 0 0 0 3.25 5.656 6.523 6.523 0 0 0 6.5 0c2-1.187 3.25-3.312 3.25-5.656ZM0 9a7.94 7.94 0 0 1 4-6.906c2.469-1.438 5.5-1.438 8 0C14.469 3.53 16 6.156 16 9c0 2.875-1.531 5.5-4 6.938-2.5 1.437-5.531 1.437-8 0A7.953 7.953 0 0 1 0 9Zm6-1v-.75c0-.063-.031-.094-.031-.156a.768.768 0 0 1-.469.156.722.722 0 0 1-.75-.75c0-.188.063-.344.156-.469-.062 0-.093-.031-.156-.031-.719 0-1.25.563-1.25 1.25V8H6ZM4.75 5C5.969 5 7 6.031 7 7.25V8c0 .563-.469 1-1 1H3.5c-.563 0-1-.438-1-1v-.75C2.5 6.031 3.5 5 4.75 5Zm6.75 2.25a.722.722 0 0 1-.75-.75c0-.156.031-.313.125-.438-.125-.03-.25-.062-.375-.062A1.5 1.5 0 0 0 9 7.5V8h3v-.5c0-.125-.031-.25-.063-.375a.718.718 0 0 1-.437.125ZM10.5 5C11.875 5 13 6.125 13 7.5v.563c0 .53-.438.937-.969.937H8.937A.92.92 0 0 1 8 8.062V7.5C8 6.125 9.094 5 10.5 5Zm-7.375 5.625a.513.513 0 0 1 .5-.625h8.719c.344 0 .562.313.5.625-.125.563-.313 1.063-.594 1.5-.906 1.438-2.469 2.375-4.219 2.375h-.469a4.984 4.984 0 0 1-4.437-3.875Zm8.25 1.031a2.348 2.348 0 0 0-.813-.156 2.58 2.58 0 0 0-2.5 2c1.376 0 2.594-.75 3.313-1.844Z"
            />
        </svg>
    );
}
