import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Summary from '../summary/Summary';
import {GoogleOAuthProvider} from '@react-oauth/google';
import Paywall from '../paywall/Paywall';
import Invitation from '../invitation';
import ProfileContextProvider from '../contexts/profile/ProfileContextProvider';
import Unauthorized from '../unauthorized/Unauthorized';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import ThemeContextProvider from '../contexts/theme/ThemeContextProvider';
import Landing from '../landing/Landing';
import {AuthContextProvider} from '../contexts/auth/AuthContext';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const queryClient = new QueryClient();

export default function App() {
    document.body.classList.add(`theme-${global.eightify.theme}`);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeContextProvider>
                <GoogleOAuthProvider clientId={googleClientId}>
                    <BrowserRouter>
                        <AuthContextProvider>
                            <ProfileContextProvider>
                                <Routes>
                                    <Route>
                                        {global.eightify.variant === 'landing' && (
                                            <Route index path="*" element={<Landing />} />
                                        )}
                                        {global.eightify.variant !== 'landing' && (
                                            <>
                                                <Route index element={<Summary />} />
                                                <Route path="unauthorized" element={<Unauthorized />} />
                                                <Route path="paywall" element={<Paywall />} />
                                                <Route path="invite" element={<Invitation />} />
                                            </>
                                        )}
                                    </Route>
                                </Routes>
                            </ProfileContextProvider>
                        </AuthContextProvider>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </ThemeContextProvider>
        </QueryClientProvider>
    );
}
