export const TranscriptIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" fill="none">
        <path
            fill="currentColor"
            d="M0 2C0 0.90625 0.875 0 2 0H16C17.0938 0 18 0.90625 18 2V12C18 13.125 17.0938 14 16 14H2C0.875 14 0 13.125 0 12V2ZM6.25 5.5C6.6875 5.5 7.09375 5.71875 7.34375 6C7.625 6.3125 8.09375 6.34375 8.40625 6.0625C8.71875 5.8125 8.75 5.3125 8.46875 5C7.9375 4.40625 7.125 4 6.25 4C4.59375 4 3.25 5.34375 3.25 7C3.25 8.65625 4.59375 10 6.25 10C7.125 10 7.9375 9.625 8.46875 9C8.75 8.71875 8.71875 8.21875 8.40625 7.96875C8.09375 7.6875 7.625 7.71875 7.34375 8C7.09375 8.3125 6.6875 8.5 6.25 8.5C5.40625 8.5 4.75 7.84375 4.75 7C4.75 6.1875 5.40625 5.5 6.25 5.5ZM10.75 7C10.75 6.1875 11.4062 5.5 12.25 5.5C12.6875 5.5 13.0938 5.71875 13.3438 6C13.625 6.3125 14.0938 6.34375 14.4062 6.0625C14.7188 5.8125 14.75 5.3125 14.4688 5C13.9375 4.40625 13.125 4 12.25 4C10.5938 4 9.25 5.34375 9.25 7C9.25 8.65625 10.5938 10 12.25 10C13.125 10 13.9375 9.625 14.4688 9C14.75 8.71875 14.7188 8.21875 14.4062 7.96875C14.0938 7.6875 13.625 7.71875 13.3438 8C13.0938 8.3125 12.6875 8.5 12.25 8.5C11.4062 8.5 10.75 7.84375 10.75 7Z"
        />
    </svg>


)
