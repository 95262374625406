import {useCallback, useEffect, useRef, useState, useContext} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {ProfileContext} from './ProfileContext';
import EightifyAPI from '../../api/api';
import {getFromStorage, saveToStorage} from '../../util/util';
import {useNavigate} from 'react-router-dom';
import {PROFILE_QUERY_KEY, useProfile} from './useProfile';
import {AuthContext} from "../auth/AuthContext";

export default function ProfileContextProvider({children}) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {setIsAuthorized} = useContext(AuthContext);

    const {data: profileData, isLoading: isLoadingProfile, isFetching: isFetchingProfile} = useProfile();

    const [language, setLanguage] = useState('EN');
    const [selectedTab, setSelectedTab] = useState(null);
    const isSummaryTypeInitializedRef = useRef(false);

    const refreshProfile = useCallback(() => {
        if (isFetchingProfile) {
            return;
        }

        queryClient.invalidateQueries({
            queryKey: PROFILE_QUERY_KEY,
        });
    }, []);

    const changeLanguage = useCallback((lang) => {
        setLanguage(lang);
        saveToStorage('language', lang);
    }, []);

    const changeSelectedTab = useCallback((type) => {
        setSelectedTab(type);
        saveToStorage('last_selected_summary_type', type);
    }, []);

    useEffect(() => {
        getFromStorage('last_selected_summary_type').then((lastSelectedTab) => {
            setSelectedTab(lastSelectedTab || 'insights');
        });
    }, []);

    useEffect(() => {
        if (profileData) {
            if (!isSummaryTypeInitializedRef.current) {
                isSummaryTypeInitializedRef.current = true;

                getFromStorage('last_selected_summary_type').then((lastSelectedSummaryType) => {
                    if (!lastSelectedSummaryType) {
                        changeSelectedTab('insights');
                    }
                });
            }
        }
    }, [profileData]);

    const setUserSeenInstantOffer = useCallback(() => {
        queryClient.setQueryData(PROFILE_QUERY_KEY, (oldData) => ({...oldData, showInstantOffer: false}));
        return EightifyAPI.seenInstantOffer();
    }, []);

    const setUserSeenReviewDialog = useCallback((type, rating) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY, (oldData) => ({...oldData, reviewDialog: null}));
        EightifyAPI.seenReviewDialog(type, rating);
    }, []);

    const clearProfileData = useCallback(async () => {
        await queryClient.invalidateQueries({
            predicate: () => true,
            refetchType: 'none',
        });
    }, []);

    const logout = async (navigateOptions) => {
        setIsAuthorized(false);
        saveToStorage('invite_key', null);
        saveToStorage('token', null);
        saveToStorage('language', null);
        saveToStorage('last_selected_summary_type', null);

        await clearProfileData();
        navigate(`/unauthorized`, navigateOptions);
    };

    return (
        <ProfileContext.Provider
            value={{
                isAgreedToTrial: true,
                showInstantOffer: false,
                reviewDialog: null,
                ...profileData,
                isLoadingProfile,
                language,
                setLanguage: changeLanguage,
                selectedTab: selectedTab,
                setSelectedTab: changeSelectedTab,
                setUserSeenInstantOffer,
                setUserSeenReviewDialog,
                refreshProfile,
                clearProfileData,
                logout,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
}
