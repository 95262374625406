import {useContext, useEffect, useState} from 'react';
import cn from 'classnames';
import './styles.css';
import {heightIframeObserver} from '../../util/util';
import {LayoutContext} from './LayoutContext';
import {useFrameHeight} from '../../hooks/ui/useFrameHeight';
import {ThemeContext} from '../../contexts/theme/ThemeContext';

const LayoutHeader = ({children, className, ...props}) => {
    const {setHeaderHeight} = useContext(LayoutContext);
    const id = 'layout-header';

    useEffect(() => {
        const unsubscribe = heightIframeObserver(document.getElementById(id), (height) => {
            setHeaderHeight(height);
        });

        return () => {
            unsubscribe();
            setHeaderHeight(0);
        };
    }, []);

    return (
        <div id={id} className={cn('eightify-layout__header', className)} {...props}>
            {children}
        </div>
    );
};

const LayoutContent = ({children, className, ...props}) => {
    const {contentId, setContentHeight} = useContext(LayoutContext);
    useFrameHeight();

    useEffect(() => {
        const unsubscribe = heightIframeObserver(document.getElementById(contentId), (height) => {
            setContentHeight(height);
        });

        return () => {
            unsubscribe();
            setContentHeight(0);
        };
    }, [contentId]);

    return (
        <div id={contentId} className={cn('eightify-layout__content', className)} {...props}>
            {children}
        </div>
    );
};

const LayoutFooter = ({children, className, ...props}) => {
    const {setFooterHeight} = useContext(LayoutContext);
    const id = 'layout-footer';

    useEffect(() => {
        const unsubscribe = heightIframeObserver(document.getElementById(id), (height) => {
            setFooterHeight(height);
        });

        return () => {
            unsubscribe();
            setFooterHeight(0);
        };
    }, []);

    return (
        <div id={id} className={cn('eightify-layout__footer', className)} {...props}>
            {children}
        </div>
    );
};

export const Layout = ({id, children, className, ...props}) => {
    const contentId = id ?? 'layout-content';
    const {variant} = useContext(ThemeContext);
    const [contentHeight, setContentHeight] = useState(null);
    const [headerHeight, setFooterHeight] = useState(null);
    const [footerHeight, setHeaderHeight] = useState(null);

    return (
        <LayoutContext.Provider
            value={{
                contentId,
                contentHeight: contentHeight ?? 0,
                headerHeight: headerHeight ?? 0,
                footerHeight: footerHeight ?? 0,
                setContentHeight,
                setHeaderHeight,
                setFooterHeight,
            }}
        >
            <div
                className={cn('eightify-layout', className, {
                    'eightify-layout--demo': variant === 'demo',
                    'eightify-layout--landing': variant === 'landing',
                })}
                {...props}
            >
                {children}
            </div>
        </LayoutContext.Provider>
    );
};

Layout.Header = LayoutHeader;
Layout.Content = LayoutContent;
Layout.Footer = LayoutFooter;
