import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import Const from '../../../const';
import './styles.css';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import {useLogger} from '../../../util/util';
import {usePopularVideos} from './usePopularVideos';
import VideoGallery from './VideoGallery';

const hostname = process.env.REACT_APP_IDEAS_BACKEND_HOST;

export default function ReviewDialog({className, type, registeredAt, onClose}) {
    const logger = useLogger(hostname);
    const {isSafari} = useContext(ThemeContext);
    // possible states: [opener, good_review]
    const [state, setState] = useState('opener');
    const [rating, setRating] = useState(null);
    const days = registeredAt ? Math.floor((new Date() - new Date(registeredAt)) / (1000 * 60 * 60 * 24)) : 'many';
    const {data: popularVideos} = usePopularVideos();

    const reviewUrl = useMemo(() => {
        if (isSafari) {
            return Const.goToSafariAppUrl;
        }

        return Const.goToChromeAppReviewUrl;
    }, [isSafari]);

    const close = useCallback(
        (delay) => {
            setTimeout(() => {
                onClose(type, rating);
            }, delay || 0);
        },
        [rating, type]
    );

    const onClickLinkReview = () => {
        logger.logEvent('conversion', {
            name: 'review',
            result: 'click',
            link: reviewUrl,
        });
    };

    const onWontLeaveReview = () => {
        logger.logEvent('conversion', {
            name: 'review',
            result: 'wont',
        });
        close();
    };

    useEffect(() => {
        if (!rating) {
            return;
        }

        if (rating > 3) {
            setState('good_review');
        } else {
            window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLScXjF0BCaCjjmAGK0PZr9namqHtSeAlB7eOeWz5MfxO8oRRtQ/viewform',
                '_blank'
            );
            close();
        }
    }, [rating]);

    if (state === 'good_review') {
        return (
            <div className={cn('review-dialog__container', className)}>
                <VideoGallery className="review-dialog__gallery" videos={popularVideos ?? []} />

                <div className="review-dialog__header">
                    <h3 className="review-dialog__title">
                        Fantastic! Please review us on the Chrome Store for a special gift
                    </h3>
                    <div className="review-dialog__cross-icon" onClick={onWontLeaveReview}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.18359 1.49609L5.90234 4.77734L9.15625 8.03125C9.42969 8.27734 9.42969 8.6875 9.15625 8.93359C8.91016 9.20703 8.5 9.20703 8.25391 8.93359L4.97266 5.67969L1.71875 8.93359C1.47266 9.20703 1.0625 9.20703 0.816406 8.93359C0.542969 8.6875 0.542969 8.27734 0.816406 8.00391L4.07031 4.75L0.816406 1.49609C0.542969 1.25 0.542969 0.839844 0.816406 0.566406C1.0625 0.320312 1.47266 0.320312 1.74609 0.566406L5 3.84766L8.25391 0.59375C8.5 0.320312 8.91016 0.320312 9.18359 0.59375C9.42969 0.839844 9.42969 1.25 9.18359 1.49609Z"
                                fill="black"
                                fillOpacity="0.48"
                            />
                        </svg>
                    </div>
                </div>

                <p className="review-dialog__description">
                    Get the handpicked selection of the top 100 videos on AI, business, productivity and health to get
                    ahead of 99% of people.
                </p>

                <div className="review-dialog__buttons">
                    <a
                        className={cn('primary-button', 'review-dialog__support-email-link')}
                        target="_blank"
                        href={reviewUrl}
                        onClick={() => onClickLinkReview()}
                        onMouseUp={() => close(250)}
                    >
                        <span>Leave a review</span>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className={cn('review-dialog__container', className)}>
            <div className="review-dialog__header">
                <h3 className="review-dialog__title">It’s been {days} days since you’ve installed Eightify</h3>
                <div className="review-dialog__cross-icon" onClick={onWontLeaveReview}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.18359 1.49609L5.90234 4.77734L9.15625 8.03125C9.42969 8.27734 9.42969 8.6875 9.15625 8.93359C8.91016 9.20703 8.5 9.20703 8.25391 8.93359L4.97266 5.67969L1.71875 8.93359C1.47266 9.20703 1.0625 9.20703 0.816406 8.93359C0.542969 8.6875 0.542969 8.27734 0.816406 8.00391L4.07031 4.75L0.816406 1.49609C0.542969 1.25 0.542969 0.839844 0.816406 0.566406C1.0625 0.320312 1.47266 0.320312 1.74609 0.566406L5 3.84766L8.25391 0.59375C8.5 0.320312 8.91016 0.320312 9.18359 0.59375C9.42969 0.839844 9.42969 1.25 9.18359 1.49609Z"
                            fill="black"
                            fillOpacity="0.48"
                        />
                    </svg>
                </div>
            </div>

            <p className="review-dialog__description">Has it been helpful? How would you rate your experience?</p>

            <div className="rating-stars__container">
                <input
                    className={cn({'rating-stars_star--selected': rating > 0})}
                    id="star5"
                    name="rating"
                    type="radio"
                    onChange={() => setRating(5)}
                />
                <label htmlFor="star5" className="rating-stars_star"></label>
                <input
                    className={cn({'rating-stars_star--selected': rating > 1})}
                    id="star4"
                    name="rating"
                    type="radio"
                    onChange={() => setRating(4)}
                />
                <label htmlFor="star4" className="rating-stars_star"></label>
                <input
                    className={cn({'rating-stars_star--selected': rating > 2})}
                    id="star3"
                    name="rating"
                    type="radio"
                    onChange={() => setRating(3)}
                />
                <label htmlFor="star3" className="rating-stars_star"></label>
                <input
                    className={cn({'rating-stars_star--selected': rating > 3})}
                    id="star2"
                    name="rating"
                    type="radio"
                    onChange={() => setRating(2)}
                />
                <label htmlFor="star2" className="rating-stars_star"></label>
                <input
                    className={cn({'rating-stars_star--selected': rating > 4})}
                    id="star1"
                    name="rating"
                    type="radio"
                    onChange={() => setRating(1)}
                />
                <label htmlFor="star1" className="rating-stars_star"></label>
            </div>
        </div>
    );
}
