import {createContext} from 'react';

export const ProfileContext = createContext({
    isLoadingProfile: false,
    balance: null,
    activeSubscription: null,
    isAgreedToTrial: false,
    showInstantOffer: false,
    reviewDialog: null,
    language: null,
    selectedTab: null,
    canHaveTrial: null,
    isTrial: null,
    trialRestrictionViewcount: null,
    trialRestrictionDuration: null,
    trialRestrictionN: 0,
    trialRestrictedTranslations: null,
    trialInitialAmount: 0,
    trialRefillPeriod: null,
    extensionRegisteredAt: null,
    setLanguage: () => {},
    setSelectedTab: () => {},
    setUserSeenInstantOffer: () => {},
    setUserSeenReviewDialog: () => {},
    refreshProfile: () => {},
    clearProfileData: () => {},
    logout: () => {},
});
