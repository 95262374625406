export default function TwitterIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none">
            <path
                fill="currentColor"
                d="M11.656.5h2.188L9.03 6.031 14.72 13.5H10.28l-3.5-4.531L2.813 13.5H.592L5.75 7.625.312.5h4.563L8 4.656 11.656.5Zm-.781 11.688h1.219L4.219 1.75H2.906l7.969 10.438Z"
            />
        </svg>
    );
}
