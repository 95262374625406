import cn from 'classnames';
import arrowRightIcon from '../../../assets/icons/arrow-right-icon.svg';
import Const from '../../../const';
import classes from './menu.module.css';

export default function MenuItem({title, icon, hasArrow, link, className, onClick}) {
    const item = (
        <div className={cn(classes.menuItem, className)} onClick={onClick}>
            {icon}
            {title}
            <div style={{display: 'flex', flexGrow: 1}} />
            {hasArrow && <img src={arrowRightIcon} alt="Arrow right icon" />}
        </div>
    );

    if (link) {
        return (
            <a
                className={classes.menuItemLink}
                target="_top"
                href={`mailto:${Const.supportEmail}`}
            >
                {item}
            </a>
        );
    }

    return item;
}
