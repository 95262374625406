import React, {Fragment} from 'react';
import SummaryContainer from './SummaryContainer';
import '../Summary.css';

export default function InsightsView({insights, isRtl = false}) {
    const insightsGroups = insights.insights;
    const showInsights = insightsGroups && insightsGroups.length > 0
    const hideGroupTitle = showInsights && insightsGroups[0].title === 'Key insights'
    return (
        <SummaryContainer>
            {!showInsights && (
                <div className="insight-title" style={{marginBottom: 8, marginLeft: 4, marginTop: 6}}>
                    🤓 No insights found
                </div>
            )}
            {showInsights && <div className="tab-title">Key insights</div>}
            {showInsights && (
                <div style={{position: 'relative'}} dir={isRtl ? 'rtl' : 'ltr'}>
                    {insightsGroups.map((insightsGroup, index) => (
                        <Fragment key={index}>
                            {!hideGroupTitle && <div className="insight-title">{insightsGroup.title}</div>}
                            <ul className="insights">
                                {insightsGroup.insights.map((element, index) => {
                                    const parts = Array.from(element);
                                    const firstSpaceIndex = parts.findIndex((char) => /\s/.test(char));

                                    let emoji = ''
                                    let text = element;

                                    if (firstSpaceIndex !== -1) {
                                        const firstPart = parts.slice(0, firstSpaceIndex).join('').trim();

                                        if (firstPart.match(/(\p{Emoji})/u)) {
                                            emoji = firstPart;
                                            text = parts.slice(firstSpaceIndex + 1).join('');
                                        }
                                    }

                                    return (
                                        <li key={index}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div className="emoji">{emoji}</div>
                                                <div className="title">{text}</div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Fragment>
                    ))}
                </div>
            )}
        </SummaryContainer>
    );
}
