import {useLogger, formatTimestamp} from '../../util/util';

const hostname = process.env.REACT_APP_IDEAS_BACKEND_HOST;

export const useSummaryCopy = ({videoId, language, activeSubscription, summary, insights, transcript}) => {
    const logger = useLogger(hostname);

    const copyInsights = (c) => {
        if (!insights) return;

        let htmlText = '';
        let plainText = '';

        const insightGroups = insights.insights;

        for (let i = 0; i < insightGroups.length; i++) {
            const insightGroup = insightGroups[i];

            htmlText += `<h3>${insightGroup.title}</h3>\n\n`;
            plainText += `${insightGroup.title}\n\n`;

            htmlText += '<ul>\n';
            for (let j = 0; j < insightGroup.insights.length; j++) {
                const insight = insightGroup.insights[j];

                htmlText += `<li>${insight}</li>\n`;
                plainText += `${insight}\n`;
            }

            htmlText += '</ul></br>\n\n';
        }

        if (!activeSubscription) {
            plainText += '\nSummary for https://youtu.be/' + videoId + ' by www.eightify.app';
            htmlText +=
                '\n' +
                "<p><i>Summary for: <a href='https://youtu.be/" +
                videoId +
                "'>https://youtu.be/" +
                videoId +
                "</a> by <a href='https://www.eightify.app'>Eightify</a></i></p>";
        }

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([plainText], {type: 'text/plain'}),
            'text/html': new Blob([htmlText], {type: 'text/html'}),
        });

        navigator.clipboard.write([clipboardItem]);
        logger.logEvent('summary-copy', {
            videoId: videoId,
            language: language,
        });
    };

    const copySummary = (copyFull, copyTimestamps, copyEmoji) => {
        let htmlText = '';
        let plainText = '';

        plainText = 'TLDR: ' + summary.title + '\n\n';
        htmlText = '<h3>' + summary.title + '</h3>\n\n';

        htmlText += '<ul>\n';
        for (let i = 0; i < summary.summary.length; i++) {
            const part = summary.summary[i];

            let plainTimestamp = '';
            let htmlTimestamp = '';
            if (copyTimestamps) {
                const timestamp = formatTimestamp(part.startTime);

                plainTimestamp = timestamp + ' ';
                htmlTimestamp = `<a href="https://www.youtube.com/watch?v=${videoId}&t=${Math.round(
                    part.startTime
                )}">${timestamp}</a> `;
            }

            if (copyFull || !copyTimestamps) {
                plainText += `${i + 1}. ${plainTimestamp}${copyEmoji ? part.emoji : ''} ${part.tldr}\n`;
            } else {
                plainText += `${plainTimestamp}${copyEmoji ? part.emoji : ''} ${part.tldr}\n`;
            }

            let sublist = '';
            if (copyFull) {
                plainText += part.keyIdeas.map((idea, idx) => `\t${i + 1}.${idx + 1} ${idea}`).join('\n') + '\n\n';
                sublist = '<ul>' + part.keyIdeas.map((idea) => `<li>${idea}</li>`).join('\n') + '</ul>';
            }

            htmlText += `<li>${htmlTimestamp}${copyEmoji ? part.emoji : ''} ${part.tldr}${sublist}</li>\n`;
        }
        htmlText += '</ul></br>\n\n';

        if (!activeSubscription) {
            plainText += '\nSummary for https://youtu.be/' + videoId + ' by www.eightify.app';
            htmlText +=
                '\n' +
                "<p><i>Summary for: <a href='https://youtu.be/" +
                videoId +
                "'>https://youtu.be/" +
                videoId +
                "</a> by <a href='https://www.eightify.app'>Eightify</a></i></p>";
        }

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([plainText], {type: 'text/plain'}),
            'text/html': new Blob([htmlText], {type: 'text/html'}),
        });

        navigator.clipboard.write([clipboardItem]);
        logger.logEvent('summary-copy', {
            videoId: videoId,
            language: language,
        });
    };

    const copyTranscript = (copyTimestamps) => {
        let htmlText = '';
        let plainText = '';

        htmlText += '<ul>\n';
        for (let i = 0; i < transcript.transcript.transcript_parts.length; i++) {
            const paragraph = transcript.transcript.transcript_parts[i];
            const time = transcript?.transcript.paragraph_timings[i];

            let plainTimestamp = '';
            let htmlTimestamp = '';
            if (copyTimestamps) {
                const timestamp = formatTimestamp(time);

                plainTimestamp = timestamp + ' ';
                htmlTimestamp = `<a href="https://www.youtube.com/watch?v=${videoId}&t=${Math.round(
                    time
                )}">${timestamp}</a> `;
            }

            plainText += `${i + 1}. ${plainTimestamp} ${paragraph.join('\n')}\n`;

            htmlText += `<li>${htmlTimestamp} ${paragraph.join('\n')}</li>\n`;
        }
        htmlText += '</ul></br>\n\n';

        if (!activeSubscription) {
            plainText += '\nSummary for https://youtu.be/' + videoId + ' by www.eightify.app';
            htmlText +=
                '\n' +
                "<p><i>Summary for: <a href='https://youtu.be/" +
                videoId +
                "'>https://youtu.be/" +
                videoId +
                "</a> by <a href='https://www.eightify.app'>Eightify</a></i></p>";
        }

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([plainText], {type: 'text/plain'}),
            'text/html': new Blob([htmlText], {type: 'text/html'}),
        });

        navigator.clipboard.write([clipboardItem]);
        logger.logEvent('summary-copy', {
            videoId: videoId,
            language: language,
        });
    };

    return {copyInsights, copySummary, copyTranscript};
};
