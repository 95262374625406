import React, {Fragment, useContext, useEffect, useState} from 'react';
import cn from 'classnames';
import TippyTooltip from '../../../components/TippyTooltip';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import checkButtonIcon from '../../../assets/icons/check-icon.svg';
import copy from '../../images/copy.svg';
import checkboxClasses from './checkbox.module.css';
import {ProfileContext} from '../../../contexts/profile/ProfileContext';
import classes from './copy-summary-button.module.css';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import IconButton from '../../../components/IconButton';
import {ChevronUpIcon} from '../../../components/icons/ChevronUpIcon';
import {CopyTextIcon} from '../../../components/icons/CopyTextIcon';
import {useFrameHeight} from '../../../hooks/ui/useFrameHeight';
import {getFromStorage, saveToStorage} from '../../../util/util';

const COPY_SUMMARY_TEXT_SETTINGS_DEFAULTS = {
    copyTimestamps: false,
    copyFull: false,
    copyEmoji: true,
};

export default function CopySummaryTextButton(props) {
    const copySettingsMenuId = 'copy-settings-menu';
    const {onCopySummary, onCopyInsights, onCopyTranscript, style} = props;

    const {selectedTab} = useContext(ProfileContext);
    const {theme} = useContext(ThemeContext);
    const {requestContentHeight} = useFrameHeight();

    const queryClient = useQueryClient();
    const {data: settings} = useQuery({
        queryKey: ['settings', 'copySummaryText'],
        queryFn: () =>
            getFromStorage('copySummaryTextSettings').then((settings) => ({
                ...COPY_SUMMARY_TEXT_SETTINGS_DEFAULTS,
                ...(settings ?? {}),
            })),
        initialData: COPY_SUMMARY_TEXT_SETTINGS_DEFAULTS,
    });

    const {mutate: mutateSettings} = useMutation({
        mutationKey: ['settings', 'copySummaryText'],
        mutationFn: (newSettings) => saveToStorage('copySummaryTextSettings', newSettings),
        onSuccess: () => {
            queryClient.invalidateQueries(['settings', 'copySummaryText']);
        },
    });

    const copiedBackgroundColor = theme === 'dark' ? 'rgba(49, 180, 62, 0.12)' : 'rgba(49, 180, 62, 0.12)';
    const [copied, setCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            const menuElement = document.getElementById(copySettingsMenuId);
            const menuHeight = menuElement?.offsetHeight ?? 0;

            requestContentHeight(menuHeight + 32);
        } else {
            requestContentHeight(0);
        }
    }, [isOpen]);

    const updateSettingsValue = (key, value) => {
        mutateSettings({
            ...settings,
            [key]: value,
        });
    };

    const hasCopyEmojiSetting = ['summary'].includes(selectedTab);
    const hasCopyFullSetting = ['summary'].includes(selectedTab);
    const hasCopyTimestampsSetting = ['summary', 'transcript'].includes(selectedTab);

    const copySummary = () => {
        if (selectedTab === 'insights') {
            onCopyInsights();
        } else if (selectedTab === 'summary') {
            onCopySummary(settings.copyFull, settings.copyTimestamps, settings.copyEmoji);
        } else if (selectedTab === 'transcript') {
            onCopyTranscript(settings.copyTimestamps);
        }

        if (copied) {
            clearTimeout(copied);
        }
        setCopied(
            setTimeout(() => {
                setCopied(false);
            }, 1200)
        );
        setIsOpen(false);
    };

    const handleCopySummary = () => {
        if (selectedTab === 'insights') {
            copySummary();
        } else {
            setIsOpen(!isOpen);
        }
    };

    const tipContent = selectedTab === 'insights' ? 'Copy insights' : 'Copy summary';

    return (
        <div className="copy-wrapper" style={style}>
            <TippyTooltip placement="left-start" content={tipContent}>
                <div
                    className={cn('menu-button', {
                        'menu-button--close': isOpen,
                    })}
                    style={{
                        backgroundColor: copied ? copiedBackgroundColor : undefined,
                    }}
                    onClick={handleCopySummary}
                >
                    {copied ? <img alt="" src={checkButtonIcon} /> : isOpen ? <ChevronUpIcon /> : <CopyTextIcon />}
                </div>
            </TippyTooltip>

            {isOpen && settings && (
                <Fragment>
                    <div id={copySettingsMenuId} className={cn('menu-layout', classes.settings)}>
                        {hasCopyTimestampsSetting && (
                            <div className={classes.settingsRow}>
                                <span onClick={() => updateSettingsValue('copyTimestamps', !settings.copyTimestamps)}>
                                    Timestamps
                                </span>

                                <div className={checkboxClasses.checkboxWrapper}>
                                    <input
                                        id="timestamps"
                                        type="checkbox"
                                        checked={settings.copyTimestamps}
                                        onChange={() => updateSettingsValue('copyTimestamps', !settings.copyTimestamps)}
                                        className={checkboxClasses.tgl}
                                    />
                                    <label className={checkboxClasses.tglBtn} htmlFor="timestamps" />
                                </div>
                            </div>
                        )}

                        {hasCopyFullSetting && (
                            <div className={classes.settingsRow}>
                                <span onClick={() => updateSettingsValue('copyFull', !settings.copyFull)}>
                                    Full summary
                                </span>
                                <div className={checkboxClasses.checkboxWrapper}>
                                    <input
                                        id="full"
                                        type="checkbox"
                                        checked={settings.copyFull}
                                        onChange={() => updateSettingsValue('copyFull', !settings.copyFull)}
                                        className={checkboxClasses.tgl}
                                    />
                                    <label className={checkboxClasses.tglBtn} htmlFor="full" />
                                </div>
                            </div>
                        )}

                        {hasCopyEmojiSetting && (
                            <div className={classes.settingsRow}>
                                <span onClick={() => updateSettingsValue('copyEmoji', !settings.copyEmoji)}>Emoji</span>
                                <div className={checkboxClasses.checkboxWrapper}>
                                    <input
                                        id="emoji"
                                        type="checkbox"
                                        checked={settings.copyEmoji}
                                        onChange={() => updateSettingsValue('copyEmoji', !settings.copyEmoji)}
                                        className={checkboxClasses.tgl}
                                    />
                                    <label className={checkboxClasses.tglBtn} htmlFor="emoji" />
                                </div>
                            </div>
                        )}

                        <IconButton icon={copy} className={classes.copyButton} onClick={copySummary}>
                            Copy text
                        </IconButton>
                    </div>

                    <div className="menu-layout-background" onClick={() => setIsOpen(false)} />
                </Fragment>
            )}
        </div>
    );
}
