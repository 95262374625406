import './styles.css';

export default function UnlimitedSubscriptionPlan(props) {
    const {title, price, isActive, description, highlightedDescription, hoverEnabled, onClick} = props;

    const subscriptionItemClass = 'subscription-item' + (hoverEnabled ? ' with-hover' : '');

    return (
        <div onClick={onClick}>
            {isActive && <div style={{height: 11}} />}

            <div className={subscriptionItemClass} style={{border: isActive && '2px solid #FB651E'}}>
                {isActive && <div className="subscription-item-active">Current plan</div>}

                <div className="subscription-item-title">
                    <div>{title}</div>
                    {highlightedDescription ? (
                        <div className="subscription-item-highlighted-description">{highlightedDescription}</div>
                    ) : (
                        description && <div className="subscription-item-description">{description}</div>
                    )}
                </div>

                {price && <div className="subscription-item-price">{price}</div>}
            </div>
        </div>
    );
}
