import {getFromStorage, saveToStorage} from '../util/util';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

const ONBOARDING_STATE_QUERY_KEY = ['onboarding', 'state'];

/*
{ seenDemo: undefined }
*/
export const useOnboardingStateQuery = () => {
    return useQuery({
        queryKey: ONBOARDING_STATE_QUERY_KEY,
        queryFn: async () => {
            const storageOnboardingState = await getFromStorage('onboardingState');

            const onboardingState = {};

            // get "onboarding-v2" from cookie
            const onboardingCookieName = 'onboarding-v2';
            const cookieOnboarding = document.cookie
                .split('; ')
                .find((row) => row.startsWith(onboardingCookieName))
                ?.split('=')?.[1];

            if (cookieOnboarding) {
                onboardingState.seenDemo = cookieOnboarding === 'finished';
            }

            return Object.assign(
                onboardingState,
                storageOnboardingState ?? {},
            );
        },
    });
};

export const useOnboardingStateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ONBOARDING_STATE_QUERY_KEY,
        mutationFn: (newState) => {
            queryClient.setQueryData(ONBOARDING_STATE_QUERY_KEY, newState);
            return saveToStorage('onboardingState', newState);
        },
    });
};
