import React, {useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {ProfileContext} from '../../../contexts/profile/ProfileContext';
import classes from './summary-remove-limits-block.module.css';
import IconButton from '../../../components/IconButton';
import bolt from '../../images/bolt.svg';
import {usePricingInfo} from '../../../hooks/data/usePricingInfo';
import {Divider} from '../../../components/Divider';

export default function SummaryRemoveLimitsBlock({violatedRestriction}) {
    const navigate = useNavigate();
    const {trialRestrictionViewcount, trialRestrictionDuration} = useContext(ProfileContext);
    const {data} = usePricingInfo();

    const restrictionMessage = useMemo(() => {
        if (violatedRestriction === 'trial-duration-too-long') {
            const formattedDuration =
                trialRestrictionDuration >= 3600
                    ? `${Math.round(trialRestrictionDuration / 3600)} hour`
                    : `${Math.round(trialRestrictionDuration / 60)} mins`;

            return `Video exceeds ${formattedDuration}. Upgrade to remove limits.`;
        }

        if (violatedRestriction === 'trial-view-count-low') {
            const formattedViewCount =
                trialRestrictionViewcount >= 1000
                    ? `${Math.round(trialRestrictionViewcount / 1000)}k`
                    : trialRestrictionViewcount;

            return `Video has less than ${formattedViewCount} views. Upgrade to remove limits.`;
        }

        return `Not eligible YouTube Video for Trial`;
    }, [violatedRestriction, trialRestrictionViewcount, trialRestrictionDuration]);

    return (
        <div className={classes.removeLimitsBlock}>
            <Divider className={classes.removeLimitsLine} />

            <p className={classes.restrictionMessage}>{restrictionMessage}</p>

            <IconButton className={classes.upgradeButton} icon={bolt} onClick={() => navigate('/paywall')}>
                {data?.showTrial ? 'Start My Free Trial' : 'Upgrade'}
            </IconButton>
        </div>
    );
}
