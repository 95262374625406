import {formatTimestamp} from '../../../util/util';
import SummaryContainer from '../SummaryContainer';
import {zip} from 'lodash';
import styles from './TranscriptView.module.css';

export default function TranscriptView({transcript, isRtl, sendSeekMessage, sendGotoMessage}) {
    const isEmpty = !transcript?.transcript?.transcript_parts?.length;
    const elements = zip(transcript?.transcript.transcript_parts ?? [], transcript?.transcript.paragraph_timings ?? []);

    return (
        <SummaryContainer>
            {!isEmpty && <div className={'tab-title'}>Transcript</div>}
            {isEmpty && <div className={'tab-title'}>No transcript 😔</div>}

            {!isEmpty && (
                <div style={{position: 'relative'}} dir={isRtl ? 'rtl' : 'ltr'}>
                    <ul className={styles.transcript}>
                        {elements.map(([parts, timing], index) => (
                            <li key={index} className={styles.transcriptPart}>
                                <span>
                                    <span
                                        className="timestamp"
                                        onMouseEnter={() => sendSeekMessage(timing)}
                                        onMouseLeave={() => sendSeekMessage(-1)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            sendGotoMessage(timing);
                                        }}
                                    >
                                        {formatTimestamp(timing)}
                                    </span>{' '}
                                    {parts.map((part, i) => (
                                        <span key={i} className={styles.text}>
                                            {part}
                                        </span>
                                    ))}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </SummaryContainer>
    );
}
