import {useContext} from 'react';
import {get, set} from 'lodash';
import {getAuthHeaders} from '../services/auth';
import {AuthContext} from '../contexts/auth/AuthContext';

const useLogger = (hostname) => {
    const {isAuthorized} = useContext(AuthContext);

    return {
        logEvent: async (eventName, data) => {
            if (!isAuthorized) {
                return;
            }

            const authHeaders = getAuthHeaders();

            const headers = {...authHeaders, 'Content-Type': 'application/json'};
            await fetch(`${hostname}/event`, {
                credentials: 'include',
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    eventName: eventName,
                    eventData: data,
                }),
            });
        },
    };
};

// we don't have access to Chrome extension storage on the demo page
const fallbackStorage = {};

const saveToLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {}
};

const getFromLocalStorage = (key) => {
    try {
        const value = localStorage.getItem(key);
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

const saveToStorage = (key, value) => {
    try {
        set(fallbackStorage, key, value);
        saveToLocalStorage(key, value);
    } catch (e) {}

    window.parent.postMessage(
        {
            type: 'storage',
            key: key,
            value: value,
        },
        '*'
    );
};

const getFromStorage = (key) => {
    if (['demo', 'landing'].includes(global.eightify.variant)) {
        return new Promise((resolve) => {
            const localStorageValue = getFromLocalStorage(key);
            const fallbackValue = get(fallbackStorage, key);

            if (localStorageValue) {
                resolve(localStorageValue);
            } else if (fallbackValue) {
                resolve(fallbackValue);
            } else {
                resolve(null);
            }
        });
    }

    return new Promise((resolve) => {
        let timeout;
        let handleMessage;

        handleMessage = (event) => {
            const {type, key: resultKey, value} = event.data;

            if (type === 'get-from-storage' && resultKey === key) {
                window.removeEventListener('message', handleMessage);
                resolve(value);
                timeout && clearTimeout(timeout);
            }
        };

        window.addEventListener('message', handleMessage);

        timeout = setTimeout(() => {
            window.removeEventListener('message', handleMessage);

            const localStorageValue = getFromLocalStorage(key);
            const fallbackValue = get(fallbackStorage, key);

            if (localStorageValue) {
                resolve(localStorageValue);
            } else if (fallbackValue) {
                resolve(fallbackValue);
            } else {
                resolve(null);
            }
        }, 300);

        window.parent.postMessage({type: 'get-from-storage', key: key}, '*');
    });
};

const heightIframeObserver = (mainDiv, onChange) => {
    if (mainDiv) {
        let divHeight = mainDiv.offsetHeight;
        onChange(divHeight);
        const resizeObserver = new ResizeObserver((entries) => {
            const lastEntry = entries[entries.length - 1];
            const newHeight = lastEntry.target.scrollHeight;
            if (newHeight !== divHeight) {
                divHeight = newHeight;
                onChange(divHeight);
            }
        });
        resizeObserver.observe(mainDiv);
        return () => {
            resizeObserver.disconnect();
        };
    } else {
        return () => {};
    }
};

const sendShowBlockMessage = () => {
    window.parent.postMessage(
        {
            type: 'show-block',
        },
        '*'
    );
};

const sendSummaryReadyMessage = () => {
    window.parent.postMessage(
        {
            type: 'summary-ready',
        },
        '*'
    );
};

function decodeFlaskCookie(val) {
    if (val.indexOf('\\') === -1) {
        return val; // not encoded
    }
    val = val.slice(1, -1).replace(/\\"/g, '"');
    val = val.replace(/\\(\d{3})/g, function (match, octal) {
        return String.fromCharCode(parseInt(octal, 8));
    });
    return val.replace(/\\\\/g, '\\');
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function formatTimestamp(time = 0) {
    return time > 3600
        ? new Date(time * 1000).toISOString().substr(11, 8)
        : new Date(time * 1000).toISOString().substr(14, 5);
}

export function timestampToSeconds(timestamp) {
    const parts = timestamp.split(':').map(Number);
    if (parts.length === 3) {
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
        return parts[0] * 60 + parts[1];
    }
    return 0;
}

function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
}

const getTruncatedText = (text, maxLength = 200) => {
    if (text.length <= maxLength) return text;

    // Find the last whitespace or newline before the maxLength
    let truncatedIndex = maxLength;
    while (truncatedIndex > 0 && text[truncatedIndex] !== ' ' && text[truncatedIndex] !== '\n') {
        truncatedIndex--;
    }

    // If no whitespace or newline is found in the substring, just truncate at the maxLength
    if (truncatedIndex === 0) truncatedIndex = maxLength;

    return text.substring(0, truncatedIndex) + '…';
};

export {
    saveToStorage,
    getFromStorage,
    heightIframeObserver,
    useLogger,
    delay,
    sendShowBlockMessage,
    sendSummaryReadyMessage,
    decodeFlaskCookie,
    formatTimestamp,
    formatNumber,
    getTruncatedText,
};
