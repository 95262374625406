import React, {forwardRef, useImperativeHandle} from 'react';
import sparkles from '../../../header/images/sparkles.svg';
import IconButton from '../../../components/IconButton';
import {useOnboardingStateMutation} from '../../useOnboardingState';

export const navigateToLoginPage = () => {
    window.parent.location.href = `${process.env.REACT_APP_IDEAS_BACKEND_HOST}/onboarding/signin?just_installed=false`;
};

const OnboardingLogin = forwardRef((_, ref) => {
    const {mutate} = useOnboardingStateMutation();

    useImperativeHandle(
        ref,
        () => {
            return {
                handleSummariseRequest: (event) => {
                    if (event.type === 'summarize') {
                        mutate({seenDemo: true});
                        navigateToLoginPage();
                    }
                },
            };
        },
        []
    );

    const onClick = () => {
        mutate({seenDemo: true});
        navigateToLoginPage();
    };

    return (
        <div className="eightify-header" style={{padding: 0}}>
            <IconButton icon={sparkles} alt="Summarize video" onClick={onClick}>
                Summarize video
            </IconButton>
        </div>
    );
});

export default OnboardingLogin;
