import styles from './VideoGallery.module.css';
import React, {useContext, useEffect, useState} from 'react';
import cn from 'classnames';
import {AnimatePresence, motion, MotionStyle} from 'framer-motion';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import {PopularVideo} from './usePopularVideos';

type VideoGalleryProps = {
    videos: PopularVideo[];
    className?: string;
};

const ANIMATION_DURATION = 0.5;
const ANIMATION_INTERVAL = 1600;

export default function VideoGallery({videos, className}: VideoGalleryProps) {
    const {theme} = useContext(ThemeContext);
    const count = videos.length;
    const [positions, setPositions] = useState(videos.map((_, index) => index)); // Initial positions

    const calculateLeft = (index: number) => {
        const visualIndex = positions.indexOf(index);

        // top card fade out
        if (visualIndex === count - 1) {
            return -64;
        }

        return 32 * visualIndex;
    };

    const calculateBackground = (index: number) => {
        const visualIndex = positions.indexOf(index);

        let opacity: number;
        if (visualIndex < 5) {
            opacity = Math.min(Math.max(0.05, 1 - visualIndex * 0.2), 1);
        } else {
            opacity = Math.min(Math.max(0.05, 1 - visualIndex * 0.05), 1);
        }

        const rgb = theme === 'dark' ? '0, 0, 0' : '255, 255, 255';

        return `linear-gradient(rgba(${rgb}, ${1 - opacity}), rgba(${rgb}, ${
            1 - opacity
        })), url(https://i.ytimg.com/vi/${videos[index].videoId}/sddefault.jpg) no-repeat center / cover`;
    };

    const calculateOpacity = (index: number) => {
        const visualIndex = positions.indexOf(index);

        // top card fade out
        if (visualIndex === count - 1) {
            return 0;
        }

        return 1;
    };

    const calculateBlur = (index: number) => {
        const visualIndex = positions.indexOf(index);

        return `blur(${0.5 * visualIndex}px)`;
    };

    const calculateScale = (index: number) => {
        const visualIndex = positions.indexOf(index);

        // top card fade out
        if (visualIndex === count - 1) {
            return 1.05;
        }

        return 1 - 0.1 * visualIndex;
    };

    const calculateZindex = (index: number) => {
        const visualIndex = positions.indexOf(index);

        return count - visualIndex;
    };

    const getItemStyle = (index: number): MotionStyle => ({
        background: `url(https://i.ytimg.com/vi/${videos[index].videoId}/sddefault.jpg) no-repeat center / cover`,
        willChange: 'left, opacity, filter, transform, z-index, background',
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setPositions((prevPositions) => [...prevPositions.slice(1), prevPositions[0]]);
        }, ANIMATION_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={cn(styles.container, className)}>
            <AnimatePresence>
                {videos.map((card, index) => (
                    <motion.div
                        className={cn(styles.card, {
                            [styles.topCard]: positions.indexOf(index) === count - 1,
                        })}
                        key={card.videoId}
                        initial={{
                            background: calculateBackground(index),
                        }}
                        animate={{
                            left: calculateLeft(index),
                            scale: calculateScale(index),
                            zIndex: calculateZindex(index),
                            background: calculateBackground(index),
                            filter: calculateBlur(index),
                            opacity: calculateOpacity(index),
                        }}
                        transition={{
                            default: {
                                // Apply the easing and duration to all properties except those explicitly defined
                                duration: ANIMATION_DURATION,
                                ease: [0.17, 0.2, 0.37, 1],
                            },
                            zIndex: {
                                delay: ANIMATION_DURATION * 0.37,
                            },
                        }}
                        style={getItemStyle(index)}
                    ></motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
}
