import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

export const useCurrentVideoId = () => {
    const [videoId, setVideoId] = useState(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const spVideoId = searchParams.get('videoId');
        if (spVideoId) {
            setVideoId(spVideoId);
        }
        const globalVideoId = global.eightify?.videoId;
        if (globalVideoId) {
            setVideoId(globalVideoId);
        }
    }, [searchParams]);

    useEffect(() => {
        global.eightify ??= {};
        global.eightify.videoId = videoId;
    }, [videoId]);

    useEffect(() => {
        const handleMessage = (event) => {
            const {type, videoId: messageVideoId} = event.data;
            if (type === 'videoId' && messageVideoId !== videoId) {
                setVideoId(messageVideoId);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [videoId]);

    return videoId;
};
