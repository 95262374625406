export default function ArrowTurnDownLeft() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" fill="none">
            <path
                fill="currentColor"
                d="M15 1c0-.531.438-1 1-1 .531 0 1 .469 1 1v5a3 3 0 0 1-3 3H4.406l2.282 2.313a.964.964 0 0 1 0 1.406.964.964 0 0 1-1.407 0l-4-4a.964.964 0 0 1 0-1.406l4-4a.964.964 0 0 1 1.407 0 .964.964 0 0 1 0 1.406L4.405 7H14c.531 0 1-.438 1-1V1Z"
            />
        </svg>
    );
}
