import React, {useContext, useState} from 'react';
import SubscriptionsSubpage from './subpages/SubscriptionsSubpage';
import {useRefill} from '../hooks/data/useRefill';
import './Paywall.css';
import {usePricingInfo} from '../hooks/data/usePricingInfo';
import {ProfileContext} from '../contexts/profile/ProfileContext';
import {Layout} from '../components/Layout';
import PageLoader from '../components/PageLoader';
import {useProfile} from '../contexts/profile/useProfile';
import Header from '../header/Header';
import {useCurrentVideoId} from '../hooks/data/useCurrentVideoId';
import {useLocation, useNavigate} from 'react-router-dom';
import PageTitle from '../components/PageTitle';

const Paywall = () => {
    const {balance, activeSubscription, selectedTab, setSelectedTab, language} = useContext(ProfileContext);
    const videoId = useCurrentVideoId();
    const {data, isLoading} = usePricingInfo();
    const {data: refillInfo} = useRefill();
    const navigate = useNavigate();
    const [comments, setComments] = useState();
    const location = useLocation();
    const takeAllFromIt = !!location.state?.takeAllFromIt;

    useProfile({refetchOnWindowFocus: true});

    const isLegacyPackagesUser = data?.packageBalance != null && !activeSubscription;

    const changeSelectedTab = (type) => {
        if (selectedTab === type) return;

        setSelectedTab(type);
        if (type === 'comments' && !comments) {
            navigate('/');
        }
    };
    return (
        <Layout>
            {!takeAllFromIt && (
                <Layout.Header>
                    <Header
                        summaryLoaded={true}
                        changeSelectedTab={changeSelectedTab}
                        videoId={videoId}
                        language={language}
                    />
                </Layout.Header>
            )}
            <Layout.Content>
                {isLoading && <PageLoader />}
                {!isLoading && data && isLegacyPackagesUser && (
                    <div className="package-info">
                        <PageTitle title="Take all from it" />
                        <p className="package-info__message">
                            <span>
                                No more packages, only <b>subscriptions</b> now!
                            </span>
                            {data.packageBalance > 0 && (
                                <>
                                    <br />
                                    <span>
                                        {' '}
                                        Don’t worry, your <b>{data.packageBalance}</b> summaries are safe.
                                    </span>
                                    <br />
                                    <span> You can use them up before subscribing.</span>
                                </>
                            )}
                        </p>
                    </div>
                )}
                {!isLoading && data && !isLegacyPackagesUser && (
                    <SubscriptionsSubpage
                        balance={balance}
                        prices={data.prices}
                        showTrial={data.showTrial}
                        refillInfo={refillInfo}
                        takeAllFromIt={takeAllFromIt}
                    />
                )}
            </Layout.Content>
        </Layout>
    );
};

export default Paywall;
