import Const from '../../../const';
import cn from 'classnames';
import classes from './language-picker.module.css';
import {useContext, useEffect, useRef} from 'react';
import {CheckIcon} from '../../../components/icons/CheckIcon';
import {ThemeContext} from "../../../contexts/theme/ThemeContext";

function getId(code) {
    return `language-item-${code}`;
}

const LangItem = ({name, code, isSelected, disabled, onSelect}) => {
    const itemClassNames = [disabled ? '' : classes.languageItemEnabled, classes.languageItem].join(' ');
    const itemNameClassNames = [
        classes.languageItemName,
        disabled ? '' : classes.languageItemEnabledName,
        isSelected ? classes.languageItemSelectedName : '',
    ].join(' ');
    const id = getId(code);

    return (
        <div id={id} className={itemClassNames} onClick={disabled ? undefined : onSelect}>
            <label className={classes.languageItemLabel}>{code}</label>
            <span className={itemNameClassNames}>{name}</span>
            {!!isSelected && (
                <i className={classes.languageItemCheck}>
                    <CheckIcon />
                </i>
            )}
        </div>
    );
};

export default function LanguageMenu({selectedValue, disabled, onSelect}) {
    const {variant} = useContext(ThemeContext);
    const containerElRef = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const id = getId(selectedValue);
            const selectedItemEl = document.getElementById(id);
            const containerEl = containerElRef.current;

            if (!selectedItemEl || !containerEl) {
                return;
            }

            const scrollPosition =
                selectedItemEl.offsetTop - containerEl.offsetHeight / 2 + selectedItemEl.offsetHeight / 2;

            containerEl.scrollTop = scrollPosition;
        }, 10);

        return () => {
            clearTimeout(timeout);
        };
    }, [selectedValue]);

    return (
        <div ref={containerElRef} className={cn(classes.languageMenu, {
            [classes.languageMenuLanding]: variant === 'landing',
        })}>
            {Const.languages.map((item) => (
                <LangItem
                    key={item.value}
                    isSelected={selectedValue === item.code}
                    name={item.nativeName}
                    code={item.code}
                    disabled={!item.default && disabled}
                    onSelect={() => onSelect(item.value)}
                />
            ))}
        </div>
    );
}
