const languages = [
    {name: 'English', value: 'EN', code: 'EN', nativeName: 'English', default: true},
    {name: 'Arabic', value: 'AR', code: 'AR', nativeName: 'العربية'},
    {name: 'Bulgarian', value: 'BG', code: 'BG', nativeName: 'Български'},
    {name: 'Bengali', value: 'BN', code: 'BN', nativeName: 'বাংলা'},
    {name: 'Chinese (simplified)', value: 'ZH', code: 'CN', nativeName: '简体中文'},
    {name: 'Czech', value: 'CS', code: 'CS', nativeName: 'Čeština'},
    {name: 'Danish', value: 'DA', code: 'DA', nativeName: 'Dansk'},
    {name: 'German', value: 'DE', code: 'DE', nativeName: 'Deutsch'},
    {name: 'Estonian', value: 'ET', code: 'ET', nativeName: 'Eesti'},
    {name: 'Greek', value: 'EL', code: 'EL', nativeName: 'Ελληνικά'},
    {name: 'Spanish', value: 'ES', code: 'ES', nativeName: 'Español'},
    {name: 'Finnish', value: 'FI', code: 'FI', nativeName: 'Suomi'},
    {name: 'French', value: 'FR', code: 'FR', nativeName: 'Français'},
    {name: 'Hebrew', value: 'HE', code: 'HE', nativeName: 'עברית'},
    {name: 'Hindi', value: 'HI', code: 'HI', nativeName: 'हिन्दी'},
    {name: 'Hungarian', value: 'HU', code: 'HU', nativeName: 'Magyar'},
    {name: 'Indonesian', value: 'ID', code: 'ID', nativeName: 'Indonesia'},
    {name: 'Italian', value: 'IT', code: 'IT', nativeName: 'Italiano'},
    {name: 'Japanese', value: 'JA', code: 'JA', nativeName: '日本語'},
    {name: 'Korean', value: 'KO', code: 'KO', nativeName: '한국어'},
    {name: 'Lithuanian', value: 'LT', code: 'LT', nativeName: 'Lietuvių'},
    {name: 'Latvian', value: 'LV', code: 'LV', nativeName: 'Latviešu'},
    {name: 'Malay', value: 'MS', code: 'MS', nativeName: 'Bahasa Melayu'},
    {name: 'Dutch', value: 'NL', code: 'NL', nativeName: 'Nederlands'},
    {name: 'Norwegian', value: 'NB', code: 'NB', nativeName: 'Norsk'},
    {name: 'Polish', value: 'PL', code: 'PL', nativeName: 'Polski'},
    {name: 'Portuguese (Brazilian)', value: 'PT-BR', code: 'PT', nativeName: 'Português (Brasil)'},
    {name: 'Portuguese (European)', value: 'PT-PT', code: 'PT', nativeName: 'Português (Portugal)'},
    {name: 'Romanian', value: 'RO', code: 'RO', nativeName: 'Română'},
    {name: 'Russian', value: 'RU', code: 'RU', nativeName: 'Русский'},
    {name: 'Slovak', value: 'SK', code: 'SK', nativeName: 'Slovenčina'},
    {name: 'Slovenian', value: 'SL', code: 'SL', nativeName: 'Slovenščina'},
    {name: 'Swedish', value: 'SV', code: 'SV', nativeName: 'Svenska'},
    {name: 'Thai', value: 'TH', code: 'TH', nativeName: 'ไทย'},
    {name: 'Turkish', value: 'TR', code: 'TR', nativeName: 'Türkçe'},
    {name: 'Filipino', value: 'TL', code: 'TL', nativeName: 'Filipino'},
    {name: 'Traditional Chinese', value: 'ZH-TW', code: 'TW', nativeName: '繁體中文'},
    {name: 'Ukrainian', value: 'UK', code: 'UK', nativeName: 'Українська'},
    {name: 'Urdu', value: 'UR', code: 'UR', nativeName: 'اردو'},
    {name: 'Vietnamese', value: 'VI', code: 'VI', nativeName: 'Tiếng Việt'},
    {name: 'Zulu', value: 'ZU', code: 'ZU', nativeName: 'isiZulu'}
];

const languageMap = languages.reduce((acc, language) => {
    acc[language.value] = language;
    return acc;
}, {});

const Const = {
    referralInviteSummaryReward: 10,
    supportEmail: 'support@eightify.app',

    freeTrialSummaryCount: 3,

    errors: {
        suspiciousActivity: 'suspicious-activity',
        noTranscript: 'no-transcript',
    },

    languages,
    languageMap,

    manageSafariSubscriptionUrl: 'https://apps.apple.com/account/subscriptions',
    goToSafariAppUrl: 'https://apps.apple.com/us/app/eightify/id6448126776',
    goToChromeAppReviewUrl: 'https://chromewebstore.google.com/detail/eightify-ai-youtube-summa/cdcpabkolgalpgeingbdcebojebfelgb/reviews',
};

export default Const;
