import React from 'react';
import './styles.css';
import {useNavigate} from "react-router-dom";

export default function UpgradeToSeeTLDRButton() {
    const navigate = useNavigate()
    return (
        <div className={'upgrade-to-see-tlrd-button-block'}>
            <button  className={'upgrade-to-see-tlrd-button'}  onClick={() => navigate('/paywall', {state: {takeAllFromIt: true}})}>
                Upgrade to see TLDR
            </button>
        </div>
    );
}
