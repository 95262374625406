import React from 'react';
import classes from './icon-button.module.css';
import cn from 'classnames';

export default function IconButton({icon, alt, className, children, onClick}) {
    return (
        <button className={cn(classes.iconButton, className)} onClick={onClick}>
            <img className={classes.iconButtonImg} src={icon} alt={alt} />
            {children}
        </button>
    );
}
