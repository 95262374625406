import React, {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import TippyTooltip from '../../../components/TippyTooltip';
import check from '../../../assets/icons/check-icon.svg';
import EightifyAPI from '../../../api/api';
import {CopyLinkIcon} from '../../../components/icons/CopyLinkIcon';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';

export default function CopySummaryLinkButton(props) {
    const {style, videoId, language} = props;
    const {theme, variant} = useContext(ThemeContext);

    const copiedBackgroundColor = theme === 'dark' ? 'rgba(49, 180, 62, 0.12)' : 'rgba(49, 180, 62, 0.12)';
    const [copied, setCopied] = useState(false);
    const resetCopiedRef = useRef(null);
    const [userRequestVideoId, setUserRequestVideoId] = useState(null);

    let {data, isFetching} = useQuery({
        enabled: userRequestVideoId === videoId,
        queryKey: ['videoPageUrl', {videoId, language}],
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            if (failureCount > 3) {
                return false;
            }

            return error?.status !== 401;
        },
        queryFn: async () => {
            const promise =
                variant === 'landing'
                    ? EightifyAPI.getLandingVideoPageUrl({
                          videoId,
                          language,
                      })
                    : EightifyAPI.getVideoPageUrl({videoId, language});

            const url = await promise;

            copySummary(url);

            return url;
        },
    });

    const copySummary = (url) => {
        navigator.clipboard.writeText(url);
        setCopied(true);

        resetCopiedRef.current = setTimeout(() => {
            setCopied(false);
        }, 1200);
    };

    const handleCopySummary = () => {
        if (data && userRequestVideoId === videoId) {
            copySummary(data);
            return;
        }

        setUserRequestVideoId(videoId);
    };

    useEffect(() => {
        if (userRequestVideoId === videoId) {
            return;
        }

        setUserRequestVideoId(null);
    }, [videoId]);

    return (
        <div className="copy-wrapper" style={style}>
            <TippyTooltip placement="left-start" content="Copy link">
                <div
                    className="menu-button"
                    style={{
                        backgroundColor: copied ? copiedBackgroundColor : undefined,
                    }}
                    onClick={handleCopySummary}
                >
                    {isFetching ? (
                        <div className="menu-button__loader" />
                    ) : copied ? (
                        <img alt="" src={check} />
                    ) : (
                        <CopyLinkIcon />
                    )}
                </div>
            </TippyTooltip>
        </div>
    );
}
