import React, {Fragment} from 'react';
import SuspiciousActivitySubpage from '../../subpages/SuspiciousActivitySubpage';
import Const from '../../../const';

export default function SummaryErrorView(props) {
    const {error} = props;
    const errorType = typeof error === 'object' && error.type;

    if (errorType === Const.errors.suspiciousActivity) {
        return <SuspiciousActivitySubpage />;
    }

    if (errorType === Const.errors.noTranscript) {
        return <pre className="warning-text">{error.message}</pre>;
    }

    if (typeof error === 'string') {
        return <pre className="error-text">Error: {error}</pre>;
    }

    return <Fragment />;
}
