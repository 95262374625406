import React from 'react';
import './styles.css';
import wandWhite from '../../images/wand-white.svg';
import {ChevronDownIcon} from '../../../components/icons/ChevronDownIcon';

export default function MakeFullSummaryButton(props) {
    const {hasActiveSubscription, onClick} = props;

    if (hasActiveSubscription) {
        return (
            <button type="submit" className="show-full-summary-button" onClick={onClick}>
                Show full summary&nbsp;
                <ChevronDownIcon />
            </button>
        );
    }

    return (
        <button type="submit" className="full-summary-button" onClick={onClick}>
            Make a full summary&nbsp;
            <span className="full-price">
                <img alt="" src={wandWhite} />1
            </span>
        </button>
    );
}
