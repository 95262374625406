import {useQuery} from '@tanstack/react-query';
import {apiClient} from '../../../api/axiosClient';

export interface PopularVideo {
    title: string;
    videoId: string;
    channelId: string | null;
    thumbnailUrl: string | null;
    avatarUrl: string | null;
    authorName: string;
}

interface PopularVideoResponse {
    data: PopularVideo[];
    hasMore: boolean;
}

const pageSize = 10;
const language = 'EN';

export function usePopularVideos() {
    return useQuery({
        queryKey: ['popular', 'videos', {language}],
        cacheTime: 1000 * 60 * 60 * 24,
        keepPreviousData: true,
        retry: 3,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: async (): Promise<PopularVideo[]> => {
            const url = new URL('popular/videos', apiClient.defaults.baseURL);
            url.searchParams.append('limit', String(pageSize));
            url.searchParams.append('offset', String(0));
            url.searchParams.append('period', 'week');
            url.searchParams.append('language', language);

            const response = await apiClient.get<PopularVideoResponse>(url.toString());

            if (response.status !== 200) {
                throw new Error('Popular videos request failed');
            }

            if (!response.data?.data?.length) {
                return [];
            }

            return response.data.data;
        },
    });
}
