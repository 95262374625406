import {useMutation, useQueryClient} from '@tanstack/react-query';
import EightifyAPI from '../api/api';
import {v4} from 'uuid';
import {PROFILE_QUERY_KEY} from '../contexts/profile/useProfile';

export const useCheckoutMutation = (params) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...(params ?? {}),
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: PROFILE_QUERY_KEY,
                refetchType: 'none',
            });
            params?.onSuccess?.(...args);
        },
        mutationFn: ({lookupKey, idempotencyKey}) =>
            EightifyAPI.checkout({
                lookupKey,
                idempotencyKey: idempotencyKey || v4(),
            }),
    });
};
