import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../contexts/auth/AuthContext';
import {ProfileContext} from '../../contexts/profile/ProfileContext';

export function useTabsSync() {
    const {setIsAuthorized} = useContext(AuthContext);
    const {clearProfileData} = useContext(ProfileContext);
    const navigate = useNavigate();

    useEffect(() => {
        const handleStorageChange = (event) => {
            const {type, key, newValue} = event;
            if (type !== 'storage') {
                return;
            }

            if (key === 'token') {
                let token;

                try {
                    if (newValue === 'null') {
                        token = null;
                    } else if (newValue === 'undefined') {
                        token = undefined;
                    } else {
                        token = JSON.parse(newValue);
                    }
                } catch (e) {
                    token = newValue;
                }

                global.eightify.token = token;

                if (token) {
                    setIsAuthorized(true);
                    navigate(`/`);
                } else {
                    setIsAuthorized(false);
                    clearProfileData();
                    navigate(`/unauthorized`);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
}
