import React, {Fragment, useState} from 'react';
import '../../Summary.css';

import SummaryContainer from '../SummaryContainer';
import {formatNumber, getTruncatedText, timestampToSeconds} from '../../../util/util';
import {LikeIcon} from '../../../components/icons/LikeIcon';
import {ArrowCircleIcon} from "../../../components/icons/ArrowCircleIcon";
import SummarizeButton from "../SummarizeButton";
import UpgradeToSeeTLDRButton from "../UpgradeToSeeTLDRButton";
import {ChevronDownIcon} from "../../../components/icons/ChevronDownIcon";
import {ChevronUpIcon} from "../../../components/icons/ChevronUpIcon";
import {AvatarIcon} from "../../../components/icons/AvatarIcon";
import AvatarPicture from "../AvatarPicture";

const randomBluredText = (seed: string) => {
  const messages = [
    "Tried to unblur with dev tools? Sorry, our content isn't as easily seen as your browser history!",
    "Shoutout to those using dev tools to unblur: Even if you succeed, we blurred the punchline!",
    "Using dev tools to see the summary? Hope you're better at coding than sneaking!",
    "Tip: Using dev tools to unblur might not work. But it's a great way to feel like a hacker for a minute!",
    "We've heard adjusting the HTML in dev tools makes the summary even more mysterious. Dare to try?",
    "Congrats on opening dev tools! Spoiler alert: The unblur button is right next to the 'upgrade plan' one.",
    "Caught you peeking with dev tools! No spoilers here. The TLDR? Upgrade and find out!",
    "Trying dev tools to unveil the magic? Just a heads up: our content isn't written in invisible ink!",
    "Roses are red, our content is blurred, using dev tools? That's just absurd!",
    "Knock, knock. Who's there? Dev. Dev who? Dev tools won't reveal our secrets, but upgrading sure will!"
  ];

  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    hash = (hash << 5) - hash + seed.charCodeAt(i);
    hash |= 0;  // Convert to 32bit integer
  }
  const index = Math.abs(hash) % messages.length;
  return messages[index];
};



function formatTextWithTimestamps(text: string, sendSeekMessage: (timestamp: number) => void, sendGotoMessage: (timestamp: number) => void) {
    // 00:44, 0:42, 10:00:00
    const timestamp_regex = /(\d{1,2}:\d{2}(?::\d{2})?)/g;
    const parts = text.split(timestamp_regex);
    return parts.map((part, index) => {
        if (timestamp_regex.test(part)) {
            return (
                <span
                    key={index}
                    className="timestamp"
                    onMouseEnter={() => sendSeekMessage(timestampToSeconds(part))}
                    onMouseLeave={() => sendSeekMessage(-1)}
                    onClick={(e) => {
                        e.stopPropagation();
                        sendGotoMessage(timestampToSeconds(part));
                    }}
                >
                    {part}
                </span>
            );
        }
        return part;
    });
}



export default function CommentsView({ commentsData, sendSeekMessage, sendGotoMessage, activeSubscription, handleSummarizeClick, isLoading, isRtl = false, videoId}: {
    commentsData: {
        title: string
        comments?: {
            tldr?: string,
            top_comments?: {
                id: string,
                author_display_name: string,
                author_profile_image_url: string,
                author_channel_url: string,
                video_id: string,
                text_display: string,
                positive_rating: boolean,
                like_count: number,
                published_at: string,
                updated_at: string,
                total_reply_count: number
            }[],
        }
    },
    sendSeekMessage: (time: number) => void,
    sendGotoMessage: (time: number) => void,
    isRtl: boolean,
    activeSubscription: boolean
    isLoading: boolean
    videoId: string
    handleSummarizeClick: () => void

}) {
    const [expandedComments, setExpandedComments] = useState<string[]>([]);

    const comments = commentsData.comments
    if (!(comments && comments.top_comments && comments.top_comments.length > 0 )) {
        return <SummaryContainer><div className="comments-container"><div className="summary-title">No comments found 🫢</div></div></SummaryContainer>
    }

    return (
        <SummaryContainer>
            <div className="comments-container">
                <div className={'tab-title'}>Top comments</div>
                {comments.tldr && (
                    <div className="comments-tldr-section">
                        <span className="tldr-prefix">TLDR </span>
                        <span className="tldr-text">{comments.tldr}</span>
                    </div>
                )}
                {!comments.tldr && activeSubscription && (
                    <div className={'button-block'}>
                        <SummarizeButton
                            isLoading={isLoading}
                            selectedTab={'comments'}
                            handleSummarizeClick={handleSummarizeClick}
                        />
                    </div>
                )}
                {!comments.tldr && !activeSubscription && (
                    <Fragment>
                        <div className="comments-tldr-section">
                            <span className="tldr-fish">{randomBluredText(commentsData.title || "")}</span>
                        </div>
                        <div className={'button-block'}>
                            <UpgradeToSeeTLDRButton />
                        </div>
                    </Fragment>
                )}
                <ul className="comments-list">
                    {comments.top_comments?.map((comment, index) => {
                        const isExpanded = expandedComments.includes(comment.id);
                        const isExpandable = comment.text_display.length > 300
                        const text = isExpanded || !isExpandable ? comment.text_display : getTruncatedText(comment.text_display);
                        return (
                            <li key={comment.id} className="comment-item">
                            <a target="_parent" href={comment.author_channel_url} className="comment-author-link">
                                <AvatarPicture className="comment-author-img" src={comment.author_profile_image_url} alt={comment.author_display_name}/>
                            </a>
                            <div className="comment-content">
                                <div className="comment-header">
                                    <a target="_parent" href={comment.author_channel_url} className="comment-author">{comment.author_display_name}</a>
                                    <div className="comment-icons">
                                        <span className="comments-like-icon"><LikeIcon/></span>
                                        <span className="comment-likes">{formatNumber(comment.like_count)}</span>
                                        <a target="_parent" href={`https://www.youtube.com/watch?v=${videoId}&lc=${comment.id}`} className="comment-link"><ArrowCircleIcon/></a>
                                    </div>
                                </div>
                                <div className={'comment-text'}>
                                    {formatTextWithTimestamps(text, sendSeekMessage, sendGotoMessage)}
                                </div>
                                {isExpandable && (<div className={'toggle-hint'}>
                                        <a
                                            onClick={e => {
                                                e.preventDefault();
                                                if (isExpanded) {
                                                    setExpandedComments(prevState => prevState.filter(id => id !== comment.id));
                                                } else {
                                                    setExpandedComments(prevState => [...prevState, comment.id]);
                                                }
                                            }}
                                        >
                                        {isExpanded ? (
                                                <>
                                                    Show less
                                                    <i className="toggle-hint__icon"><ChevronUpIcon /></i>
                                                </> ) : (
                                                <>
                                                    Read more
                                                    <i className="toggle-hint__icon"><ChevronDownIcon /></i>
                                                </>
                                            )
                                        }
                                        </a>
                                </div>)}
                            </div>
                        </li>
                    )})}
                </ul>
            </div>
        </SummaryContainer>
    );
}
