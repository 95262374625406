import React from 'react';
import classes from './upgrade-button.module.css';
import {useNavigate} from 'react-router-dom';
import iconPro from '../../images/icon-pro.svg';

export default function UpgradeButton(props) {
    const {className, text} = props;
    const navigate = useNavigate();

    const cssClass = [classes.upgradeButton, className || ''].join(' ');

    return (
        <div className={cssClass} onClick={() => navigate('/paywall')}>
            <img alt="Upgrade to Pro" src={iconPro} />
            <span className={classes.upgradeButtonText}>{text || "Upgrade"}</span>
        </div>
    );
}
