export default function InstagramIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <path
                fill="currentColor"
                d="M8 3.406c1.969 0 3.594 1.625 3.594 3.594 0 2-1.625 3.594-3.594 3.594C6 10.594 4.406 9 4.406 7A3.594 3.594 0 0 1 8 3.406Zm0 5.938c1.281 0 2.313-1.031 2.313-2.344A2.307 2.307 0 0 0 8 4.687C6.687 4.688 5.656 5.72 5.656 7A2.34 2.34 0 0 0 8 9.344Zm4.563-6.063a.84.84 0 0 0-.844-.844.84.84 0 0 0-.844.844.84.84 0 0 0 .844.844.84.84 0 0 0 .844-.844Zm2.374.844c.063 1.156.063 4.625 0 5.781-.062 1.125-.312 2.094-1.124 2.938-.813.812-1.813 1.062-2.938 1.125-1.156.062-4.625.062-5.781 0-1.125-.063-2.094-.313-2.938-1.125C1.344 12 1.094 11.03 1.031 9.906.97 8.75.97 5.281 1.031 4.125 1.094 3 1.344 2 2.156 1.187 3 .375 3.97.125 5.094.063 6.25 0 9.719 0 10.875.063 12 .126 13 .376 13.813 1.188 14.624 2 14.874 3 14.937 4.126Zm-1.5 7c.376-.906.282-3.094.282-4.125 0-1 .094-3.188-.281-4.125-.25-.594-.72-1.094-1.313-1.313C11.187 1.188 9 1.282 8 1.282c-1.031 0-3.219-.095-4.125.28a2.41 2.41 0 0 0-1.344 1.313C2.156 3.813 2.25 6 2.25 7c0 1.031-.094 3.219.281 4.125a2.37 2.37 0 0 0 1.344 1.344c.906.375 3.094.281 4.125.281 1 0 3.188.094 4.125-.281.594-.25 1.094-.719 1.313-1.344Z"
            />
        </svg>
    );
}
