import {Fragment, useContext, useMemo} from 'react';
import MenuButton from './components/MenuButton';
import 'tippy.js/dist/tippy.css';
import './styles.css';
import TabsView from './components/SummaryTypeView';
import {ProfileContext} from '../contexts/profile/ProfileContext';
import LanguagePicker from './components/LanguagePicker';
import CopySummaryTextButton from './components/CopySummaryButton/CopySummaryTextButton';
import CopySummaryLinkButton from './components/CopySummaryButton/CopySummaryLinkButton';
import {ThemeContext} from '../contexts/theme/ThemeContext';

export default function Header({
    isLoading,
    summaryLoaded,
    onCopySummary,
    onCopyInsights,
    onCopyTranscript,
    changeSelectedTab,
    isTrialMessageShown,
    videoId,
    language,
}) {
    const {trialRestrictedTranslations, isTrial, selectedTab} = useContext(ProfileContext);
    const {variant} = useContext(ThemeContext);

    const headerState = useMemo(() => {
        if (summaryLoaded && !isTrialMessageShown) {
            return 'summary-loaded';
        }

        if (isLoading) {
            return 'loading-summary';
        }

        return 'summarize';
    }, [summaryLoaded, isLoading, isTrialMessageShown]);

    return (
        <Fragment>
            <div className="eightify-header">
                {<TabsView selectedTab={selectedTab} changeSelectedTab={changeSelectedTab} />}

                <div className="eightify-header__action-bar">
                    {selectedTab !== 'transcript' && (
                        <LanguagePicker isRestricted={isTrial && !!trialRestrictedTranslations} />
                    )}

                    {headerState === 'summary-loaded' &&
                        ((selectedTab === 'summary' && onCopySummary) ||
                            (selectedTab === 'insights' && onCopyInsights) ||
                            (selectedTab === 'transcript' && onCopyTranscript)) && (
                            <CopySummaryTextButton
                                selectedTab={selectedTab}
                                onCopySummary={onCopySummary}
                                onCopyInsights={onCopyInsights}
                                onCopyTranscript={onCopyTranscript}
                            />
                        )}

                    {headerState === 'summary-loaded' && selectedTab !== 'comments' && !!videoId && (
                        <CopySummaryLinkButton videoId={videoId} language={language} />
                    )}

                    {variant === 'default' && <MenuButton />}
                </div>
            </div>
        </Fragment>
    );
}
