import React, {useContext, useEffect, useRef, useState} from 'react';
import thinkingLogo from './images/thinking-logo.svg';
import heartIcon from './images/heart-icon.svg';
import './SourcePoll.css';
import Loader from '../components/Loader';
import {ThemeContext} from '../contexts/theme/ThemeContext';
import ArrowTurnDownLeft from '../components/icons/ArrowTurnDownLeftIcon';
import {CrossIcon} from '../components/icons/CrossIcon';
import GoogleSearchIcon from './icons/GoogleSearchIcon';
import TwitterIcon from './icons/TwitterIcon';
import ChatGptIcon from './icons/ChatGptIcon';
import InstagramIcon from './icons/InstagramIcon';
import TikTokIcon from './icons/TikTokIcon';
import FriendIcon from './icons/FriendIcon';
import {shuffle} from 'lodash';

const options = shuffle([
    {
        id: 'google',
        title: 'Google Search',
        icon: <GoogleSearchIcon />,
    },
    {
        id: 'twitter',
        title: 'Twitter',
        icon: <TwitterIcon />,
    },
    {
        id: 'chat-gpt',
        title: 'ChatGPT',
        icon: <ChatGptIcon />,
    },
    {
        id: 'instagram',
        title: 'Instagram',
        icon: <InstagramIcon />,
    },
    {
        id: 'tiktok',
        title: 'TikTok',
        icon: <TikTokIcon />,
    },
    {
        id: 'friend',
        title: 'Friend',
        icon: <FriendIcon />,
    },
]);

export default function SourcePoll({isSummaryLoading, onAnswer}) {
    const {theme} = useContext(ThemeContext);
    const [isJustAnswered, setIsJustAnswered] = useState(false);
    const [isInputActive, setIsInputActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef();
    const loaderColor = theme === 'light' ? 'rgba(0, 0, 0, 0.48)' : 'rgba(255, 255, 255, 0.64)';

    const onSubmit = () => {
        setIsJustAnswered(true);
    };

    const onSelect = (value) => {
        setIsInputActive(false);
        setInputValue(value);
        setIsJustAnswered(true);
    };

    const onSelectOther = () => {
        setIsInputActive(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    };

    const onClose = () => {
        onAnswer('unknown');
    };

    useEffect(() => {
        if (isSummaryLoading || !isJustAnswered) {
            return;
        }

        const timeout = setTimeout(() => {
            setIsJustAnswered(false);
            onAnswer(inputValue);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isJustAnswered, isSummaryLoading]);

    if (isJustAnswered) {
        return (
            <div className="source-poll">
                <img className="source-poll__logo" src={heartIcon} alt="Heart Icon" />
                <h2 className="source-poll__title">Thanks!</h2>
                <div className="source-poll__status">
                    <Loader className="source-poll__status-loader" size={12} color={loaderColor} />
                    <p className="source-poll__status-text">Your summary is on the way</p>
                </div>
            </div>
        );
    }

    return (
        <div className="source-poll">
            <div className="source-poll__cross-icon" onClick={onClose}>
                <CrossIcon />
            </div>

            <img className="source-poll__logo" src={thinkingLogo} alt="Thinking Icon" />

            <h2 className="source-poll__title">Btw, how’d you find Eightify?</h2>

            <div className="source-poll__options">
                {options.map((option) => (
                    <div key={option.id} className="source-poll-option" onClick={() => onSelect(option.id)}>
                        {option.icon}
                        <span className="source-poll-option__text">{option.title}</span>
                    </div>
                ))}

                {isInputActive ? (
                    <form className="source-poll-form" onSubmit={onSubmit}>
                        <input
                            ref={inputRef}
                            type="text"
                            required
                            className="source-poll-form__input"
                            placeholder="Please specify"
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button className="source-poll-form__submit" type="submit">
                            <ArrowTurnDownLeft />
                        </button>
                    </form>
                ) : (
                    <div className="source-poll-option" onClick={onSelectOther}>
                        <span className="source-poll-option__text">Other</span>
                    </div>
                )}
            </div>
        </div>
    );
}
