import React, {Fragment, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import PrimaryButton from '../../../../../components/PrimaryButton';
import {v4} from 'uuid';
import './styles.css';
import Const from '../../../../../const';
import {ThemeContext} from '../../../../../contexts/theme/ThemeContext';
import {useCheckoutMutation} from "../../../../useCheckoutMutation";


export default function CheckoutButton(props) {
    const {title, price, className, hasActiveSubscription, onClick} = props;
    const {isSafari} = useContext(ThemeContext);
    const [checkoutUrl, setCheckoutUrl] = useState(null);
    const [error, setError] = useState(null);
    const {mutateAsync: checkoutAsync} = useCheckoutMutation({
        onSuccess: (checkoutUrl) => {
            if (checkoutUrl) {
                setCheckoutUrl(checkoutUrl);
                window.open(checkoutUrl, '_blank');
            }
        },
        onError: (error) => {
            setCheckoutUrl(null);

            // 409 is idempotency error, we don't want to show it to the user
            if (error.code !== 409) {
                setError(error.message);
            }
        }
    })

    const idempotencyKey = useMemo(() => v4(), [price?.lookup_key]);

    const checkout = useCallback(() => {
        onClick?.();

        if (isSafari) {
            setCheckoutUrl(Const.goToSafariAppUrl);
            window.open(Const.goToSafariAppUrl);
            return;
        }

        checkoutAsync({lookupKey: price.lookup_key, idempotencyKey})
    }, [price, isSafari, onClick]);

    useEffect(() => {
        setCheckoutUrl(null);
        setError(null);
    }, [isSafari, price]);

    return (
        <Fragment>
            <PrimaryButton
                className={cn('checkout-button', className)}
                title={title}
                type="primary"
                onClick={hasActiveSubscription ? undefined : () => checkout()}
            />

            {!!error && <pre className="checkout-button__error">{error}</pre>}

            {!!checkoutUrl && (
                <p className="checkout-button__link">
                    If the page did not open,{' '}
                    <a href={checkoutUrl} target="_blank" referrerPolicy="no-referrer">
                        click on this link
                    </a>
                </p>
            )}
        </Fragment>
    );
}
