import { useMutation, useQueryClient } from "@tanstack/react-query";
import {PROFILE_QUERY_KEY, useProfile} from "../contexts/profile/useProfile";
import EightifyAPI from "../api/api";

export function useSourcePoll() {
    const queryClient = useQueryClient();
    const { data: profile } = useProfile()
    const { mutate } = useMutation({
        mutationKey: [...PROFILE_QUERY_KEY, 'sourcePoll'],
        mutationFn: (value) => {
            queryClient.setQueryData(PROFILE_QUERY_KEY, (oldData) => ({...oldData, showSourcePoll: false}));

            return EightifyAPI.sendSourcePollAnswer(value);
        }
    })


    return {
        showSourcePoll: profile?.showSourcePoll,
        sendPollAnswer: mutate,
    }
}
