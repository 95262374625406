import './styles.css';
import React, {useMemo, useState} from 'react';
import refund from '../../../../images/refund.svg';
import CheckoutButton from '../CheckoutButton';
import checkmark from '../../../../images/checkmark.svg';

export default function SubscriptionPricesList(props) {
    const {prices, activeSubscription, showTrial} = props;

    const sortedPrices = useMemo(() => prices.sort(({name}) => (name === 'Annual' ? -1 : 0)), [prices]);
    const annualPriceIndex = sortedPrices.findIndex((price) => price.name === 'Annual');
    const [currentIndex, setCurrentIndex] = useState(annualPriceIndex);

    const currentPrice = useMemo(() => {
        return sortedPrices[currentIndex];
    }, [sortedPrices, currentIndex]);

    const isTrial = useMemo(() => showTrial && currentPrice.name === 'Annual', [currentPrice, showTrial]);

    if (activeSubscription) {
        return null;
    }

    const annualPrice = sortedPrices.find((price) => price.name === 'Annual');
    const annualMonthlyPriceAmount = (Math.round(annualPrice.price / 12) - 0.01).toFixed(2);

    const monthlyPrice = sortedPrices.find((price) => price.name === 'Monthly');
    const monthlyPriceAmount = monthlyPrice.price.toFixed(2);

    return (
        <>
            <div className="subscription-prices-list-items">
                {sortedPrices.map(({price, expiresAt, lookup_key, name}, index) => {
                    const isAnnual = name === 'Annual';
                    const isActive = currentPrice.lookup_key === lookup_key;

                    let title = '';
                    if (isAnnual) {
                        if (showTrial) {
                            title = '7-day free trial';
                        } else {
                            title = `Annual, $${annualMonthlyPriceAmount}/mo`;
                        }
                    } else {
                        title = `Monthly, $${monthlyPriceAmount}/mo`;
                    }

                    const description =
                        isAnnual && showTrial ? `Then only $${annualMonthlyPriceAmount}/mo, billed annually` : ``;

                    const itemClassName = `subscription-prices-list-item ${
                        isActive ? 'subscription-prices-list-item_active' : ''
                    }`;

                    return (
                        <div className={itemClassName} key={lookup_key} onClick={() => setCurrentIndex(index)}>
                            <div className={description ? "subscription-prices-list-item__content" : "subscription-prices-list-item__content_no_description"}>
                                <div className="subscription-prices-list-item__title">{title}</div>
                                <div className="subscription-prices-list-item__description">{description}</div>
                            </div>
                            <div className="subscription-prices-list-item__checkbox">
                                <img alt="" src={checkmark} />
                            </div>
                        </div>
                    );
                })}
            </div>

            <CheckoutButton
                className="subscription-prices-list-with-trial-proceed-button"
                title={isTrial ? 'Start My Free Trial' : 'Pay Now'}
                price={currentPrice}
                hasActiveSubscription={!!activeSubscription}
            />

            <div className="subscription-prices-list-with-trial-footer">
                <img alt="" src={refund} style={{marginRight: 4}} />
                If you're not satisfied, we'll provide a refund without question.
            </div>
        </>
    );
}
