import {useContext} from 'react';
import {isAxiosError} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {saveToStorage} from '../../util/util';
import {AuthContext} from '../auth/AuthContext';
import {apiClient} from '../../api/axiosClient';
import {useUnauthorizedEvent} from '../../unauthorized/useUnauthorizedEvent';

export const PROFILE_QUERY_KEY = ['profile'];

export const useProfile = (params) => {
    const navigate = useNavigate();
    const {setIsAuthorized} = useContext(AuthContext);
    const sendUnauthorizedEvent = useUnauthorizedEvent();

    return useQuery({
        refetchOnWindowFocus: false,
        ...(params ?? {}),
        enabled: window.eightify.variant !== 'landing' && (params?.enabled ?? true),
        queryKey: PROFILE_QUERY_KEY,
        retry: (failureCount, error) => {
            if (failureCount >= 5) {
                return false;
            }

            if (isAxiosError(error) && error.response?.status === 401) {
                return false;
            }

            return true;
        },
        queryFn: async () => {
            const response = await apiClient.get('/profile', {timeout: 5000});
            const profileData = response.data;

            const balance = typeof profileData?.balance === 'number' ? profileData.balance : null;
            const activeSubscription = profileData?.activeSubscription || null;
            const isTrial = profileData?.isTrial ?? !profileData?.activeSubscription;
            const canHaveTrial = profileData?.canHaveTrial !== false;
            const trialRestrictionViewcount = profileData?.trialRestrictionViewcount || null;
            const trialRestrictionDuration = profileData?.trialRestrictionDuration || null;
            const trialRestrictionN = profileData?.trialRestrictionN || null;
            const trialRestrictedTranslations = profileData?.trialRestrictedTranslations || null;
            const trialRefillPeriod = profileData?.trialRefillPeriod || 'weekly';
            const trialInitialAmount = profileData?.trialInitialAmount || trialRestrictionN;

            return {
                ...profileData,
                balance,
                activeSubscription,
                isTrial,
                canHaveTrial,
                trialRestrictionViewcount,
                trialRestrictionDuration,
                trialRestrictionN,
                trialRestrictedTranslations,
                trialRefillPeriod,
                trialInitialAmount,
            };
        },
        onSuccess: () => {
            setIsAuthorized(true);
        },
        onError: (error) => {
            if (isAxiosError(error) && error.response?.status === 401) {
                saveToStorage('token', null);
                setIsAuthorized(false);
                sendUnauthorizedEvent({isAuto: true, method: '/profile'});

                navigate('/unauthorized');
            }
        },
    });
};
