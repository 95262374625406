import classes from './summary-type-view.module.css';
import cn from 'classnames';
import {ReactNode} from 'react';
import {entries} from '../../../util/entries';
import {InsightsIcon} from '../../../components/icons/InsightsIcon';
import {SummaryIcon} from '../../../components/icons/SummaryIcon';
import {ApiSummaryType} from '../../../api/ApiSummaryType';
import {CommentLinkIcon} from '../../../components/icons/CommentLinkIcon';
import TippyTooltip from '../../../components/TippyTooltip';
import {TranscriptIcon} from '../../../components/icons/TranscriptIcon';

export default function TabsView({
    changeSelectedTab,
    selectedTab,
}: {
    changeSelectedTab: (type: string) => void;
    selectedTab: ApiSummaryType;
}) {
    const tabs: Record<ApiSummaryType, ReactNode> = {
        [ApiSummaryType.Insights]: <InsightsIcon />,
        [ApiSummaryType.Summary]: <SummaryIcon />,
        [ApiSummaryType.Comments]: <CommentLinkIcon />,
        [ApiSummaryType.Transcript]: <TranscriptIcon />,
    };
    const tooltips = new Map<ApiSummaryType, string>([
        [ApiSummaryType.Insights, 'Key insights'],
        [ApiSummaryType.Summary, 'Timestamped Summary'],
        [ApiSummaryType.Comments, 'Top comments'],
        [ApiSummaryType.Transcript, 'Transcript'],
    ]);

    return (
        <div className={classes.summaryTypeView}>
            {entries(tabs).map(
                ([type, node]) =>
                    node && (
                        <TippyTooltip key={type} placement={'right'} content={tooltips.get(type)}>
                            <div
                                key={type}
                                className={cn(classes.summaryTypeViewItem, {
                                    [classes.summaryTypeViewSelectedItem]: selectedTab === type,
                                })}
                                onClick={() => changeSelectedTab(type)}
                            >
                                {node}
                            </div>
                        </TippyTooltip>
                    )
            )}
        </div>
    );
}
