import React, {Fragment} from 'react';
import PageTitle from '../../../components/PageTitle';
import PrimaryButton from '../../../components/PrimaryButton';
import Const from '../../../const';
import PageContent from '../../../components/PageContent';
import {EmailIcon} from '../../../components/icons/EmailIcon';

export default function SuspiciousActivitySubpage() {
    return (
        <PageContent>
            <PageTitle title="Oopsy Daisy >_<" hideCloseButton />
            <div style={{height: 8}} />

            <div className="suspicious-activity-description">
                The service is temporarily unavailable because we’ve registered suspicious activity with your account.
                Please contact us to resolve the issue.
            </div>

            <div style={{height: 24}} />

            <PrimaryButton
                title={
                    <Fragment>
                        <EmailIcon /> Contact us
                    </Fragment>
                }
                type={'primary'}
                onClick={() => window.open('mailto:' + Const.supportEmail)}
            />
            <div style={{height: 12}} />
        </PageContent>
    );
}
