import {QueryKey, useQuery} from '@tanstack/react-query';
import EightifyAPI from '../../api/api';
import {isValidVideoId} from '../helpers/summary.helpers';

type UseSummaryCacheParams = {
    enabled?: boolean;
    language: string;
    videoId?: string | null;
};

export function getSummaryCacheQueryKey(params: UseSummaryCacheParams): QueryKey {
    return ['keyIdeasCache', params.videoId, {language: params.language}];
}

export function useSummaryCache(params: UseSummaryCacheParams) {
    const queryKey = getSummaryCacheQueryKey(params);
    const enabled = (params.enabled ?? true) && isValidVideoId(params.videoId);

    return useQuery({
        queryKey,
        enabled,
        staleTime: 5 * 60 * 1000,
        retry: (failureCount, response: Response) => {
            if (failureCount > 2) {
                return false;
            }

            return ![401, 402].includes(response?.status);
        },
        retryOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async () => {
            const cacheData = await EightifyAPI.checkSummaryCache({
                videoId: params.videoId!,
                language: params.language,
            });

            cacheData.videoId = params.videoId!;

            return cacheData;
        },
    });
}
