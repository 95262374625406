export const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4467 4.39755C16.7794 4.64423 16.8492 5.11395 16.6025 5.4467L9.18922 15.4467C9.05257 15.631 8.83905 15.7427 8.60969 15.7497C8.38033 15.7567 8.16038 15.6584 8.0127 15.4827L3.42599 10.0282C3.15941 9.71117 3.2003 9.23806 3.51732 8.97148C3.83435 8.70489 4.30746 8.74578 4.57404 9.06281L8.54967 13.7907L15.3975 4.5534C15.6442 4.22065 16.1139 4.15087 16.4467 4.39755Z"
            fill="currentColor"
        />
    </svg>
);
