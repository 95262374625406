import './Unauthorized.css';
import '../summary/Fading.css';
import Rive from '@rive-app/react-canvas';
import cn from 'classnames';
import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import EightifyAPI from '../api/api';
import SummaryView from '../summary/components/SummaryView';
import InsightsView from '../summary/components/InsightsView';
import {useCurrentVideoId} from '../hooks/data/useCurrentVideoId';
import TabsView from '../header/components/SummaryTypeView';
import loader from '../header/images/loader.riv';
import {useQuery} from '@tanstack/react-query';
import {useOnboardingStateMutation, useOnboardingStateQuery} from './useOnboardingState';
import OnboardingLogin, {navigateToLoginPage} from './components/OnboardingLogin';
import {sendGotoMessage, sendSeekMessage} from '../summary/helpers/summary.helpers';
import OnSiteLogin from './components/OnSiteLogin';
import {Layout} from '../components/Layout';
import {useFrameHeight} from '../hooks/ui/useFrameHeight';
import MakeFullSummaryButton from '../summary/components/MakeFullSummaryButton';
import {useSummaryFadingState} from '../summary/hooks/useSummaryFadingState';
import {useTabsSync} from '../hooks/ui/useTabsSync';

const Unauthorized = () => {
    useTabsSync();
    const location = useLocation();
    const loginComponentRef = useRef(null);
    const [pageState, setPageState] = useState('summary');
    const [selectedTab, setSelectedTab] = useState('insights');
    const [selectedParts, setSelectedParts] = useState([]);
    const videoId = useCurrentVideoId();
    const {requestContentHeight} = useFrameHeight();
    const {data: onboardingState} = useOnboardingStateQuery();
    const loginVariant = useMemo(() => {
        return onboardingState?.seenDemo ? 'on-site' : 'external';
    }, [onboardingState]);
    const {mutate: mutateOnboardingState} = useOnboardingStateMutation();

    const {data: summaryCacheInfo} = useQuery({
        enabled: !!videoId,
        queryKey: ['summaryCacheInfo', videoId],
        keepPreviousData: true,
        staleTime: 60 * 1000,
        queryFn: () => EightifyAPI.checkSummaryCache({videoId, language: 'EN'}),
    });

    const {isFetching: isSummaryLoading, data: summaryData} = useQuery({
        enabled: selectedTab === 'summary' && !!summaryCacheInfo?.summary,
        queryKey: ['cachedSummary', videoId, 'summary'],
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000,
        queryFn: () => EightifyAPI.getSummaryFromCache({videoId, summaryType: 'summary', language: 'EN'}),
    });

    const {isFetching: isInsightsLoading, data: insightsData} = useQuery({
        enabled: selectedTab === 'insights' && !!summaryCacheInfo?.insights,
        queryKey: ['cachedSummary', videoId, 'insights'],
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000,
        queryFn: () => EightifyAPI.getSummaryFromCache({videoId, summaryType: 'insights', language: 'EN'}),
    });

    const isLoading = isSummaryLoading || isInsightsLoading;
    const fadingState = useSummaryFadingState({
        isPartial:
            selectedTab === 'summary'
                ? !!summaryData?.isPartial
                : selectedTab === 'insights'
                ? !!insightsData?.isPartial
                : false,
    });

    useEffect(() => {
        if (location.state?.isLogout) {
            setPageState('login');
        }
    }, [location.state?.isLogout]);

    // Subscribe to message events from parent window
    useEffect(() => {
        const handleMessage = (event) => {
            if (event?.data) {
                loginComponentRef?.current?.handleSummariseRequest?.(event.data);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (!summaryCacheInfo) {
            return;
        }

        if (selectedTab === 'insights' && summaryCacheInfo?.insights) {
            setPageState('summary');
        } else if (selectedTab === 'summary' && summaryCacheInfo?.summary) {
            setPageState('summary');
        } else {
            setPageState('login');
        }
    }, [summaryCacheInfo, selectedTab]);

    useEffect(() => {
        requestContentHeight();
    }, [pageState]);

    const toggleSelectedPart = (part) => {
        if (selectedParts.includes(part)) {
            setSelectedParts(selectedParts.filter((item) => item !== part));
        } else {
            setSelectedParts([...selectedParts, part]);
        }
    };

    const makeFullSummary = () => {
        if (loginVariant === 'on-site') {
            setPageState('login');
            return;
        }

        mutateOnboardingState({seenDemo: true});
        navigateToLoginPage();
    };

    if (pageState === 'login') {
        return (
            <Layout>
                <Layout.Content className="unauthorized__login">
                    {loginVariant === 'on-site' ? (
                        <OnSiteLogin ref={loginComponentRef} />
                    ) : (
                        <OnboardingLogin ref={loginComponentRef} />
                    )}
                </Layout.Content>
            </Layout>
        );
    }

    return (
        <Layout>
            <Layout.Header>
                <div className="eightify-header">
                    {isLoading && (
                        <div className="preparing-summary">
                            <Rive src={loader} className="rive-loader" />
                            Loading
                        </div>
                    )}

                    {!isLoading && (
                        <TabsView selectedTab={selectedTab} changeSelectedTab={(type) => setSelectedTab(type)} />
                    )}
                </div>
            </Layout.Header>

            <Layout.Content
                className={cn('unauthorized__summary', {
                    'masked-overflow__both': fadingState === 'both',
                    'masked-overflow__top': fadingState === 'top',
                    'masked-overflow__bottom': fadingState === 'bottom',
                    'masked-overflow': !!fadingState,
                })}
            >
                {!isLoading && (
                    <Fragment>
                        {selectedTab === 'summary' && !!summaryData && (
                            <SummaryView
                                summary={summaryData}
                                selectedParts={selectedParts}
                                toggleSelectedPart={toggleSelectedPart}
                                sendSeekMessage={sendSeekMessage}
                                sendGotoMessage={sendGotoMessage}
                            />
                        )}

                        {selectedTab === 'insights' && !!insightsData && <InsightsView insights={insightsData} />}
                    </Fragment>
                )}
            </Layout.Content>

            <Layout.Footer className="unauthorized__footer">
                {selectedTab === 'summary' && summaryData?.isPartial && (
                    <MakeFullSummaryButton onClick={makeFullSummary} />
                )}
                {selectedTab === 'insights' && insightsData?.isPartial && (
                    <MakeFullSummaryButton onClick={makeFullSummary} />
                )}
            </Layout.Footer>
        </Layout>
    );
};

export default Unauthorized;
