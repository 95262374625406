import {useNavigate} from 'react-router-dom';
import './OnSiteLogin.css';
import React, {forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {saveToStorage, sendShowBlockMessage} from '../../../util/util';
import {GoogleLogin} from '@react-oauth/google';
import signin from './signin.svg';
import arrow from './arrow.svg';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import {LayoutContext} from '../../../components/Layout/LayoutContext';
import {AuthContext} from '../../../contexts/auth/AuthContext';

const OnSiteLogin = forwardRef((_, ref) => {
    const {contentId} = useContext(LayoutContext);
    const {setIsAuthorized} = useContext(AuthContext);

    useImperativeHandle(
        ref,
        () => {
            return {
                handleSummariseRequest: (event) => {
                    if (event.type === 'summarize') {
                        sendShowBlockMessage();
                        setErrorMessage('Please sign in with Google to summarize! ⬆️️');
                        const mainDiv = document.getElementById(contentId);
                        if (mainDiv) {
                            let i = 0;
                            const interval = setInterval(() => {
                                if (i === 3) {
                                    clearInterval(interval);
                                    return;
                                }
                                mainDiv.style.backgroundColor = '#E6E6E6';
                                setTimeout(() => {
                                    mainDiv.style.backgroundColor = 'white';
                                }, 70);
                                i++;
                            }, 140);
                        }
                    }

                    // So that after auth we get the right summary
                    if (event.type === 'videoId') {
                        global.eightify.videoId = event.videoId;
                    }
                },
            };
        },
        [contentId]
    );

    const [errorMessage, setErrorMessage] = useState('');
    const {theme} = useContext(ThemeContext);

    const navigate = useNavigate();
    const hostname = process.env.REACT_APP_IDEAS_BACKEND_HOST;

    const loginSuccess = (response) => {
        fetch(`${hostname}/google-auth`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: response.credential,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    setErrorMessage(data.error);
                } else {
                    global.eightify.token = data.token;
                    saveToStorage('token', data.token);
                    setIsAuthorized(false);
                    // Navigate and send param "justLoggedIn" to indicate that we just logged in
                    navigate('/', {state: {justLoggedIn: true}});
                }
            })
            .catch((error) => {
                setErrorMessage(error);
            });
    };

    return (
        <>
            <div className="onsite-login__logo">
                <img alt="" src={signin} style={{maxWidth: 128}} />
                <img alt="" className="onsite-login__arrow" src={arrow} />
            </div>
            <div className="google-login">
                <GoogleLogin
                    onSuccess={loginSuccess}
                    onError={() => {
                        setErrorMessage('Google login failed');
                    }}
                    shape="reactangular"
                    theme={theme === 'dark' ? 'filled_blue' : 'outline'}
                    text="signin_with"
                    size="large"
                    logo_alignment="left"
                    width="200px"
                />
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </>
    );
});

export default OnSiteLogin;
