import React, {Fragment, useCallback, useState} from 'react';
import checkIcon from '../assets/icons/check-icon.svg';
import {useNavigate} from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';
import PrimaryButton from '../components/PrimaryButton';
import {useEightifyApi} from '../hooks/api/useEightifyApi';
import './styles.css';
import Const from '../const';
import Header from '../header/Header';
import {Layout} from '../components/Layout';

export default function Invitation(props) {
    const [isCopied, setIsCopied] = useState(false);
    const navigate = useNavigate();

    const {
        performRequest,
        data: link,
        isLoading,
        error,
    } = useEightifyApi({
        requestSelector: (api) => api.getReferralLink(),
        autoLoad: true,
    });

    const copyLink = useCallback(() => {
        if (!link) return;

        navigator.clipboard.writeText(link);
        setIsCopied(true);
    }, [link]);

    return (
        <Layout id="invitation">
            <Header onClick={() => navigate('/')} />
            <PageContent>
                <PageTitle title={`Get ${Const.referralInviteSummaryReward} summaries for each friend who signs up`} />

                <div className="invite-text">Share your referral link in your favorite social network</div>

                <div className="invite-link">{isLoading ? 'Loading...' : link}</div>

                <PrimaryButton
                    className="copy-link-button"
                    title={
                        isCopied ? (
                            <Fragment>
                                Link copied <img alt="" src={checkIcon} />
                            </Fragment>
                        ) : (
                            'Copy link'
                        )
                    }
                    type={isCopied ? 'success' : 'primary'}
                    isLoading={isLoading}
                    onClick={error ? performRequest : copyLink}
                />
            </PageContent>
        </Layout>
    );
}
