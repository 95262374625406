import './styles.css';

export default function PageContent(props) {
    const {style, children} = props;

    return (
        <div className="body page-content" style={style}>
            {children}
        </div>
    );
}
