import {isVariant, Variant} from '../util/Variant';
import {isTheme, Theme} from '../util/Theme';

const variants: Variant[] = Object.values(Variant);
const themes = Object.values(Theme);

declare global {
    var eightify: {
        token: string | null;
        videoId: string | null;
        variant: Variant;
        theme: Theme;
    };
}

if (!global.eightify) {
    global.eightify = {
        token: null,
        videoId: null,
        variant: Variant.Default,
        theme: Theme.Light,
    };
}

// Try to get token and videoId from GET url params
const searchParams = new URLSearchParams(window.location.search);
const spToken = searchParams.get('token');
if (spToken && !global.eightify.token) {
    global.eightify.token = spToken;
}

const spVariant = searchParams.get('variant');
if (spVariant && isVariant(spVariant)) {
    global.eightify.variant = spVariant;
}

const spTheme = searchParams.get('theme');
if (spTheme && isTheme(spTheme)) {
    global.eightify.theme = spTheme;
}

const spVideoId = searchParams.get('videoId');
if (spVideoId && !global.eightify.videoId) {
    global.eightify.videoId = spVideoId;
}

const getAuthHeaders = () => {
    const token = global.eightify ? global.eightify.token : null;

    if (token) {
        return {
            Authorization: `Bearer ${token}`,
        };
    } else {
        return null;
    }
};

const getAuthInfo = () => {
    const token = global.eightify?.token ?? null;
    if (token === null) {
        return null;
    }
    try {
        const [, payloadEncoded] = token.split('.');
        const payloadJson = atob(payloadEncoded.replace(/-/g, '+').replace(/_/g, '/'));
        const payload = JSON.parse(payloadJson);
        const {sub, exp} = payload;
        return {sub, exp};
    } catch (error) {
        return null;
    }
};

export {getAuthHeaders, getAuthInfo};
