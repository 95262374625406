import {createContext} from 'react';

export const LayoutContext = createContext({
    contentHeight: null,
    headerHeight: null,
    footerHeight: null,

    setContentHeight: () => {},
    setHeaderHeight: () => {},
    setFooterHeight: () => {},
});
