import {useQuery} from '@tanstack/react-query';
import moment from 'moment';
import {apiClient} from '../../api/axiosClient';
import {useProfile} from '../../contexts/profile/useProfile';

export const REFILL_QUERY_KEY = ['balance', 'refill'];

export const useRefill = () => {
    const {data: profileData} = useProfile();

    return useQuery({
        enabled: profileData?.balance === 0,
        queryKey: REFILL_QUERY_KEY,
        cacheTime: 5 * 60 * 1000,
        refetchOnMount: false,
        // we'll refetch every 15 seconds if the next refill is in less than 1 minute
        refetchInterval: (data) => {
            if (!data?.seconds) {
                return false;
            }

            if (data.seconds < 60) {
                return 15 * 1000;
            }

            return false;
        },
        queryFn: async () => {
            const response = await apiClient.get('/refill');

            const refillData = response.data;
            const seconds = parseInt(refillData.refill, 10);

            return {
                amount: refillData.amount,
                seconds: seconds,
                formattedTimeToRefill: moment().add(seconds, 's').fromNow(true),
            };
        },
    });
};
