import {getAuthHeaders, getAuthInfo} from '../services/auth';
import * as Sentry from '@sentry/react';
import {VideoId} from '../util/VideoId';
import {Language} from '../util/Language';
import {ApiSummaryType} from './ApiSummaryType';

export class NotAuthorizedError extends Error {}

class API {
    #baseUrl = process.env.REACT_APP_IDEAS_BACKEND_HOST;

    async getSummaryFromCache({
        videoId,
        language,
        summaryType,
    }: {
        videoId: VideoId;
        language: Language;
        summaryType: ApiSummaryType;
    }) {
        const urlParams = new URLSearchParams({
            video_id: videoId,
            language,
            type: summaryType,
        });

        const response = await fetch(`${this.#baseUrl}/key-ideas/from-cache?${urlParams.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            return data;
        }

        throw new Error(`Failed to get summary from cache: ${response.status}`);
    }

    async checkSummaryCache({videoId, language}: {videoId: VideoId; language: Language}) {
        const urlParams = new URLSearchParams({
            video_id: videoId,
            language,
        });

        const response = await fetch(`${this.#baseUrl}/key-ideas/cache?${urlParams.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            return data;
        }

        throw new Error(`Failed to check summary cache: ${response.status}`);
    }

    async logout() {
        await this.#authorisedRequest('logout', {method: 'POST', credentials: 'include'});
    }

    async getReferralLink() {
        const data = await this.#authorisedRequest('referral-link', {method: 'GET'});
        const link = data['link'];
        return link;
    }

    async getProfile() {
        const data = await this.#authorisedRequest('profile', {method: 'GET'});
        return data;
    }

    async getOffers() {
        const data = await this.#authorisedRequest('offers', {method: 'GET'});
        return data;
    }

    async seenOffer(offerName: string) {
        const data = await this.#authorisedRequest(`offers/${offerName}/seen`, {method: 'POST'});
        return data;
    }

    seenInstantOffer() {
        return this.#authorisedRequest('seen-instant-offer', {method: 'POST'}, true);
    }

    sendSourcePollAnswer(channel: string) {
        return this.#authorisedRequest('finish-source-poll', {method: 'POST', body: JSON.stringify({channel})}, true);
    }

    seenReviewDialog(type: string, rating: number | null) {
        return this.#authorisedRequest(
            'seen-review-dialog',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({type, rating}),
            },
            true
        );
    }

    async getRefillInfo() {
        const data = await this.#authorisedRequest('refill', {method: 'GET'});

        const amount = data.amount ?? 3; // default amount for backward compatibility

        return {seconds: data.refill, amount};
    }

    async getPricingInfo() {
        const data = await this.#authorisedRequest('prices', {method: 'GET'});
        const prices = data.prices;
        const showTrial = data.show_trial;
        const packageBalance = data.package_balance;

        return {prices, showTrial, packageBalance};
    }

    async getLandingSummary({
        videoId,
        language,
        summaryType,
    }: {
        videoId: VideoId;
        language: Language;
        summaryType: ApiSummaryType;
    }) {
        const url = new URL(`${this.#baseUrl}/landing/key-ideas`);

        url.searchParams.append('video_id', videoId);
        url.searchParams.append('language', language);
        url.searchParams.append('type', summaryType);

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'ui-variant': global.eightify.variant,
            },
        });

        if (!response.ok) {
            throw response;
        }

        const data = await response.json();

        return data;
    }

    async getLandingVideoPageUrl({videoId, language}: {videoId: VideoId; language: Language}) {
        const url = new URL(`${this.#baseUrl}/landing/link/summary/${videoId}`);

        if (language) {
            url.searchParams.append('language', language);
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'ui-variant': global.eightify.variant,
            },
        });

        if (!response.ok) {
            throw response;
        }

        const data = await response.json();

        return data?.url;
    }

    async getVideoPageUrl({videoId, language}: {videoId: VideoId; language: Language}) {
        const data = await this.#authorisedRequest(
            `link/summary/${videoId}?` + new URLSearchParams({language}),
            {method: 'GET'},
            true
        );

        return data?.url;
    }

    async checkout({
        lookupKey,
        idempotencyKey,
    }: {
        lookupKey: string;
        // TODO: Should we prefer UUID type? Maybe, from @types/uuid?
        idempotencyKey?: string;
    }) {
        const data = await this.#authorisedRequest('checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({lookupKey, idempotencyKey}),
        });
        const checkoutUrl = data.url;
        return checkoutUrl;
    }

    setReferralId(referralId: string) {
        return this.#authorisedRequest('referral', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({referralId}),
        });
    }

    #authorisedRequest = async (
        path: string,
        requestConfig: Partial<RequestInit>,
        validateResponse = true,
        rawResponse = false
    ) => {
        const url = `${this.#baseUrl}${path.startsWith('/') ? path : `/${path}`}`;
        const authHeaders = getAuthHeaders();

        let response;
        try {
            response = await fetch(url, {
                ...requestConfig,
                credentials: 'include',
                headers: {
                    ...authHeaders,
                    ...requestConfig.headers,
                    'ui-variant': global.eightify.variant,
                },
            });
        } catch (error) {
            // Extract the "profile" part of the path
            const pathWithoutQuery = path.split(/[?#]/)[0];

            // Check if the pathWithoutQuery is "profile" or "key-ideas" with "auto_summary=true" in the query string
            // We ignore those, because they are called on startup and they fail if the user changes the page before the request is finished
            if (
                pathWithoutQuery !== 'profile' &&
                pathWithoutQuery !== 'offers' &&
                !(pathWithoutQuery === 'key-ideas' && path.includes('auto_summary=true'))
            ) {
                const errorName = `Fetch ${pathWithoutQuery} error`;
                const errorMessage =
                    error instanceof Error
                        ? `Fetch ${pathWithoutQuery} error (${error.name}: ${error.message})`
                        : `Fetch ${pathWithoutQuery} error (${error})`;
                const customError = new Error(errorMessage);
                customError.name = errorName;

                const authInfo = getAuthInfo();

                Sentry.captureException(customError, {
                    extra: {
                        url: url,
                        method: requestConfig.method,
                        originalError: error,
                        user: authInfo,
                    },
                });
            }

            // Rethrow the error to be handled by the caller
            throw error;
        }

        if (response?.status === 401) {
            throw new NotAuthorizedError('Token is expired');
        }

        if (rawResponse) return response;

        if (!response.ok && validateResponse) {
            const httpError = new Error('REQUEST FAILED WITH STATUS CODE: ' + response.status);
            (httpError as any).code = response.status;

            throw httpError;
        }

        const data = await response.json();
        return data;
    };
}

const EightifyAPI = new API();
export default EightifyAPI;
