export default function ChatGptIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <g clip-path="url(#a)">
                <path
                    fill="currentColor"
                    d="M14.852 6.549a3.986 3.986 0 0 0-.343-3.274 4.031 4.031 0 0 0-4.341-1.934A3.987 3.987 0 0 0 7.16 0a4.032 4.032 0 0 0-3.846 2.79A3.988 3.988 0 0 0 .65 4.725a4.032 4.032 0 0 0 .496 4.727 3.986 3.986 0 0 0 .342 3.274A4.031 4.031 0 0 0 5.83 14.66 3.984 3.984 0 0 0 8.837 16a4.032 4.032 0 0 0 3.847-2.793 3.988 3.988 0 0 0 2.666-1.934 4.033 4.033 0 0 0-.498-4.725Zm-6.014 8.405a2.989 2.989 0 0 1-1.92-.694c.025-.013.067-.036.095-.053l3.186-1.84a.518.518 0 0 0 .262-.454V7.422l1.346.777a.047.047 0 0 1 .027.037v3.72a3.002 3.002 0 0 1-2.996 2.998Zm-6.442-2.751a2.986 2.986 0 0 1-.358-2.01l.095.057 3.186 1.84a.519.519 0 0 0 .523 0l3.89-2.246V11.4a.05.05 0 0 1-.019.042l-3.22 1.86a3.002 3.002 0 0 1-4.097-1.098Zm-.839-6.955a2.988 2.988 0 0 1 1.561-1.314l-.002.11v3.68a.518.518 0 0 0 .262.453l3.89 2.246-1.347.777a.048.048 0 0 1-.046.004l-3.22-1.86a3.002 3.002 0 0 1-1.098-4.095ZM12.62 7.823 8.732 5.577l1.347-.777a.048.048 0 0 1 .045-.004l3.221 1.86a3 3 0 0 1-.463 5.41v-3.79a.518.518 0 0 0-.26-.453Zm1.34-2.017a4.322 4.322 0 0 0-.094-.057L10.68 3.91a.519.519 0 0 0-.524 0L6.268 6.155V4.6a.05.05 0 0 1 .019-.041L9.506 2.7a2.998 2.998 0 0 1 4.454 3.105ZM5.535 8.577 4.188 7.8a.047.047 0 0 1-.026-.037v-3.72A2.999 2.999 0 0 1 9.079 1.74a2.235 2.235 0 0 0-.094.054l-3.186 1.84a.517.517 0 0 0-.262.453l-.002 4.49ZM6.266 7 8 6l1.732 1v2L8 10 6.266 9V7Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
