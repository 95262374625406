export const ChevronDownIcon = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.96967 6.46967C4.26256 6.17678 4.73744 6.17678 5.03033 6.46967L10.5 11.9393L15.9697 6.46967C16.2626 6.17678 16.7374 6.17678 17.0303 6.46967C17.3232 6.76256 17.3232 7.23744 17.0303 7.53033L11.0303 13.5303C10.7374 13.8232 10.2626 13.8232 9.96967 13.5303L3.96967 7.53033C3.67678 7.23744 3.67678 6.76256 3.96967 6.46967Z"
            fill="currentColor"
        />
    </svg>
);
