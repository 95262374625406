import {useContext} from 'react';
import cn from 'classnames';
import {ThemeContext} from '../../contexts/theme/ThemeContext';
import {LayoutContext} from '../../components/Layout/LayoutContext';
import {useFrameHeight} from '../../hooks/ui/useFrameHeight';

export default function SummaryContainer({children}) {
    const {variant} = useContext(ThemeContext);
    const {headerHeight, footerHeight} = useContext(LayoutContext);
    const {maxHeight} = useFrameHeight();

    return (
        <div
            className={cn('summary-container', {'summary-container__onboarding': variant === 'demo'})}
            style={{
                maxHeight: ['landing', 'demo'].includes(variant) ? 'auto' : maxHeight - headerHeight - footerHeight,
            }}
        >
            {children}
        </div>
    );
}
