import {Fragment, useEffect, useState} from 'react';
import cn from 'classnames';
import Const from '../../../const';
import classes from './language-picker.module.css';
import TippyTooltip from '../../../components/TippyTooltip';
import LanguageMenu from './LanguageMenu';
import UpgradeButton from '../../../paywall/components/UpgradeButton';
import {ChevronUpIcon} from '../../../components/icons/ChevronUpIcon';
import {useFrameHeight} from "../../../hooks/ui/useFrameHeight";
import {useLanguage} from "../../../hooks/data/useLanguage";

export default function LanguagePicker({isRestricted}) {
    const [language, setLanguage] = useLanguage();
    const {requestContentHeight} = useFrameHeight();
    const {code, value, name} = Const.languageMap[language] ?? {};
    const [isOpen, setIsOpen] = useState(false);
    const languageMenuId = 'language-menu';

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onLanguageSelect = (language) => {
        setLanguage(language);
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            const menuElement = document.getElementById(languageMenuId);
            const menuHeight = menuElement?.offsetHeight ?? 0;

            requestContentHeight(menuHeight + 32);
        } else {
            requestContentHeight(0);
        }
    }, [isOpen]);

    useEffect(() => {
        const defaultLanguage = Const.languages.find((item) => item.default)?.value;

        if (!isRestricted || !defaultLanguage || language === defaultLanguage) {
            return;
        }

        setLanguage(defaultLanguage);
    }, [isRestricted, language]);

    return (
        <div className={classes.languagePickerWrapper}>
            <TippyTooltip placement="left-start" content={name} disabled={isOpen}>
                <div className={cn('menu-button', {'menu-button--close': isOpen})} onClick={onClick}>
                    {isOpen ? <ChevronUpIcon /> : <div className={classes.languagePickerSelection}>{code}</div>}
                </div>
            </TippyTooltip>

            {isOpen && (
                <Fragment>
                    <div id={languageMenuId} className="menu-layout">
                        {isRestricted && (
                            <div className={classes.restrictedMessage}>
                                <p className={classes.restrictedMessageText}>
                                    Translations are available in Eightify Pro
                                </p>
                                <UpgradeButton className={classes.restrictedMessageButton} />
                            </div>
                        )}
                        {isOpen && (
                            <LanguageMenu selectedValue={value} disabled={isRestricted} onSelect={onLanguageSelect} />
                        )}
                    </div>

                    <div className="menu-layout-background" onClick={() => setIsOpen(false)} />
                </Fragment>
            )}
        </div>
    );
}
