import axios from 'axios';
import './api';
import {getAuthHeaders} from '../services/auth';
import {environment} from '../environment';
import {saveToStorage} from '../util/util';

export const apiClient = getAxiosClient();

axios.interceptors.request.use((config) => {
    config.headers.set('ui-variant', window.eightify.variant);

    return config;
});

export function getAxiosClient() {
    const instance = axios.create({
        baseURL: environment.apiBaseUrl,
        withCredentials: true,
        headers: {
            'ui-variant': window.eightify.variant,
        },
    });

    instance.interceptors.request.use((config) => {
        const authHeaders = getAuthHeaders();
        if (authHeaders) {
            Object.assign(config.headers, authHeaders);
        }

        return config;
    });

    instance.interceptors.response.use((response) => {
        // periodically updates token when it close to expire
        try {
            if ('token' in response.data) {
                window.eightify.token = response.data.token;
                saveToStorage('token', response.data.token);
                saveToStorage('refreshToken', response.data.refreshToken);
            }
        } catch (e) {}

        return response;
    });

    return instance;
}
