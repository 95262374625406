import {QueryKey, useQuery} from '@tanstack/react-query';
import {AxiosError, isAxiosError} from 'axios';
import {ApiSummaryType} from '../../api/ApiSummaryType';
import {isValidVideoId} from '../helpers/summary.helpers';
import {environment} from '../../environment';
import {apiClient} from '../../api/axiosClient';

type UseSummaryParams = {
    enabled?: boolean;
    source: string;
    summaryType: ApiSummaryType;
    language: string;
    isAutoSummary?: boolean | null;
    videoId?: string | null;
    commentsTldr?: boolean | null;
    onError: (error: any) => void | Promise<void>;
    onSuccess: (data: any) => void | Promise<void>;
};

export interface SummaryNotPossibleError {
    type: string;
    message: string;
}

export enum SummaryNotPossibleReason {
    OpenaiError = 'openai-error',
    TooLongTranscript = 'too-long-transcript',
    NoTranscript = 'no-transcript',
    NotPublic = 'not-public',
    NoInsights = 'no-insights',
}

export function isSummaryNotPossibleError(error: unknown): error is AxiosError<SummaryNotPossibleError> {
    if (isAxiosError(error) && [400, 500].includes(error?.response?.status!)) {
        if (
            error?.response?.data &&
            typeof error?.response?.data === 'object' &&
            'type' in error?.response?.data &&
            Object.values(SummaryNotPossibleReason).some((key) => key === error.response?.data.type)
        ) {
            return true;
        }
    }

    return false;
}

export interface SummaryRestrictionError {
    reason: string;
    message: string;
}

enum SummaryRestrictionReason {
    NotEnoughBalance = 'no-autosummary',
    NoAutoSummary = 'not-enough-balance',
    TrialViewCountLow = 'trial-view-count-low',
    TrialDurationTooLong = 'trial-duration-too-long',
    TrialTranslationsNotAllowed = 'trial-translations-not-allowed',
}

export function isSummaryRestrictionError(error: unknown): error is AxiosError<SummaryRestrictionError> {
    if (isAxiosError(error) && [402].includes(error?.response?.status!)) {
        if (
            error?.response?.data &&
            typeof error?.response?.data === 'object' &&
            'reason' in error?.response?.data &&
            Object.values(SummaryRestrictionReason).some((key) => key === error.response?.data.reason)
        ) {
            return true;
        }
    }

    return false;
}

export function getSummaryQueryKey(params: UseSummaryParams): QueryKey {
    return [
        'key-ideas',
        params.summaryType,
        params.videoId,
        {language: params.language, commentsTldr: !!params.commentsTldr, isAutoSummary: !!params.isAutoSummary},
    ];
}

export function useSummary(params: UseSummaryParams) {
    const queryKey = getSummaryQueryKey(params);
    const enabled = (params.enabled ?? true) && isValidVideoId(params.videoId);

    return useQuery({
        queryKey,
        enabled,
        staleTime: 5 * 60 * 1000,
        retryDelay: (failureCount) => Math.min(3000 ** failureCount, 10000),
        retry: (failureCount, error) => {
            if (failureCount > 2) {
                return false;
            }

            if (isAxiosError(error) && error.code === 'ECONNABORTED') {
                return true;
            }

            if (isSummaryNotPossibleError(error)) {
                return false;
            }

            if (isSummaryRestrictionError(error)) {
                return false;
            }

            return isAxiosError(error) ? [400, 429].includes(error?.response?.status!) : true;
        },
        retryOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: params.onError,
        onSuccess: params.onSuccess,
        queryFn: async () => {
            const url = new URL('key-ideas', environment.apiBaseUrl);

            url.searchParams.append('video_id', params.videoId!);
            url.searchParams.append('language', params.language);
            url.searchParams.append('auto_summary', String(!!params.isAutoSummary));
            url.searchParams.append('type', params.summaryType);
            url.searchParams.append('source', params.source);

            const response = await apiClient.get(url.toString());

            return response.data;
        },
    });
}
