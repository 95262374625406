import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useDebounce} from '@uidotdev/usehooks';
import cn from 'classnames';
import './Summary.css';
import './Fading.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {sendSummaryReadyMessage, useLogger} from '../util/util';
import * as Sentry from '@sentry/react';
import SummaryErrorView from './components/SummaryErrorView';
import Const from '../const';
import {useCurrentVideoId} from '../hooks/data/useCurrentVideoId';
import {ProfileContext} from '../contexts/profile/ProfileContext';
import TrialView from './components/TrialView';
import Header from '../header/Header';
import SummaryView from './components/SummaryView';
import InsightsView from './components/InsightsView';
import ReviewDialog from './components/ReviewDialog';
import {sendGotoMessage, sendSeekMessage} from './helpers/summary.helpers';
import {Layout} from '../components/Layout';
import {ThemeContext} from '../contexts/theme/ThemeContext';
import MakeFullSummaryButton from './components/MakeFullSummaryButton';
import SummaryRemoveLimitsBlock from './components/SummaryRemoveLimitsBlock';
import {useSummaryFadingState} from './hooks/useSummaryFadingState';
import {useSummaryCopy} from './hooks/useSummaryCopy';
import {useLanguage} from '../hooks/data/useLanguage';
import {useOnboardingStateMutation} from '../unauthorized/useOnboardingState';
import {useSourcePoll} from '../source-poll/useSourcePoll';
import SourcePoll from '../source-poll/SourcePoll';
import CommentsView from './components/CommentsView';
import SummarizeButton from './components/SummarizeButton';
import {useSummary} from './hooks/useSummary';
import {useSummaryCache} from './hooks/useSummaryCache';
import {AuthContext} from '../contexts/auth/AuthContext';
import {useUnauthorizedEvent} from '../unauthorized/useUnauthorizedEvent';
import {useRefill} from '../hooks/data/useRefill';
import TranscriptView from './components/TranscriptView';
import {useTabsSync} from '../hooks/ui/useTabsSync';

const hostname = process.env.REACT_APP_IDEAS_BACKEND_HOST;

const isRtlLanguage = (language) => {
    const rtlLanguages = ['AR', 'HE', 'UR'];
    return rtlLanguages.includes(language);
};

const Summary = () => {
    // trigger refill when needed
    useRefill();
    useTabsSync();
    const logger = useLogger(hostname);
    const location = useLocation();
    const [language, setLanguage] = useLanguage();
    const [trialMessage, setTrialMessage] = useState(false);
    const [error, setError] = useState(null);
    const [selectedParts, setSelectedParts] = useState([]);
    const {variant, theme} = useContext(ThemeContext);
    const isRtl = isRtlLanguage(language);
    const {mutate} = useOnboardingStateMutation();
    const {sendPollAnswer, showSourcePoll} = useSourcePoll();
    const sendUnauthorizedEvent = useUnauthorizedEvent();

    const clickedSummarizeRef = useRef(false);
    const languageRef = useRef(language);

    const videoId = useCurrentVideoId();

    const {isAuthorized} = useContext(AuthContext);

    const {
        extensionRegisteredAt,
        isLoadingProfile,
        isTrial,
        balance,
        activeSubscription,
        trialInitialAmount,
        reviewDialog,
        showInstantOffer,
        setUserSeenInstantOffer,
        setUserSeenReviewDialog,
        selectedTab,
        setSelectedTab,
        logout,
    } = useContext(ProfileContext);

    const navigate = useNavigate();
    const [summaryParams, setSummaryParams] = useState({
        videoId,
        language,
        summaryType: selectedTab,
        isAutoSummary: true,
        source: null,
        commentsTldr: null,
    });
    const debouncedSummaryParams = useDebounce(summaryParams, 10);

    const canUseAutoSummary = ['summary', 'insights', 'transcript'].includes(debouncedSummaryParams.summaryType);

    const {data: cacheData, isFetching: isSummaryCacheLoading} = useSummaryCache({
        enabled: canUseAutoSummary && !!debouncedSummaryParams.isAutoSummary,
        ...debouncedSummaryParams,
    });

    const shouldFetchCachedAutoSummary =
        (!isSummaryCacheLoading &&
            ((debouncedSummaryParams.summaryType === 'summary' && !!cacheData?.summary) ||
                (debouncedSummaryParams.summaryType === 'insights' && !!cacheData?.insights) ||
                (debouncedSummaryParams.summaryType === 'comments' && !!activeSubscription))) ||
        (debouncedSummaryParams === 'transcript' && !!(cacheData?.summary || cacheData?.insights));

    const {
        data,
        isFetching: isSummaryLoading,
        isError: isSummaryError,
        refetch,
    } = useSummary({
        enabled: debouncedSummaryParams.isAutoSummary ? shouldFetchCachedAutoSummary : true,
        ...debouncedSummaryParams,
        onSuccess: (data) => {
            if (data.insights || data.summary) {
                sendSummaryReadyMessage();
            }

            setError(null);
        },
        onError: async (error) => {
            if (error?.response?.status === 401) {
                sendUnauthorizedEvent({isAuto: true, method: '/summary'});
                logout({state: {redirectTo: videoId}});
                return;
            }

            let jsonData = error?.response?.data;

            if (error?.response?.status === 402) {
                const reason = jsonData?.reason;

                if (reason === 'not-enough-balance') {
                    navigate(`/paywall`);
                }
                if (reason === 'trial-duration-too-long') {
                    setTrialMessage('trial-duration-too-long');
                }
                if (reason === 'trial-comments-tldr-not-allowed') {
                    setTrialMessage('trial-comments-tldr-not-allowed');
                }
                if (reason === 'trial-view-count-low') {
                    setTrialMessage('trial-view-count-low');
                }
                if (reason === 'trial-translations-not-allowed') {
                    logger.logEvent('error', {isTrial, activeSubscription, balance, ...debouncedSummaryParams});
                    setTrialMessage('trial-translations-not-allowed');
                }
                return;
            }

            const errorType = jsonData?.type;

            if ([Const.errors.suspiciousActivity, Const.errors.noTranscript].includes(errorType)) {
                setError(jsonData);
                return;
            }

            const message =
                (jsonData && jsonData.message) ||
                'Something went wrong 😿 Please try again later. We are working on it.';

            setError(message);
        },
    });

    const isLoading = isSummaryLoading;

    const summaryData = debouncedSummaryParams.summaryType === 'summary' ? data : null;
    const insightsData = debouncedSummaryParams.summaryType === 'insights' ? data : null;
    const commentsData = debouncedSummaryParams.summaryType === 'comments' ? data : null;
    const transcriptData = debouncedSummaryParams.summaryType === 'transcript' ? data : null;

    const showReviewDialog = !!reviewDialog && !isSummaryError;
    const isPartial = ['summary', 'insights'].includes(debouncedSummaryParams.summaryType) && !!data?.isPartial;
    const violatedRestriction =
        ['summary', 'insights'].includes(debouncedSummaryParams.summaryType) && !!data?.violatedRestriction;

    const fadingState = useSummaryFadingState({
        isPartial,
    });

    const {copySummary, copyInsights, copyTranscript} = useSummaryCopy({
        videoId,
        language,
        activeSubscription,
        summary: summaryData,
        insights: insightsData,
        transcript: transcriptData,
    });

    const changeSelectedTab = (type) => {
        if (selectedTab === type) return;

        setSelectedTab(type);
        setError(null);
        setSelectedParts([]);

        setSummaryParams((params) => ({
            ...params,
            summaryType: type,
            isAutoSummary: false,
            source: 'summary-type-change',
        }));
    };

    const closeTrialMessage = () => {
        setTrialMessage(false);

        if (!videoId) {
            Sentry.captureException('Still no Video ID after close Trial Message');
        }

        setSummaryParams((params) => ({
            ...params,
            isAutoSummary: false,
            source: 'trial-ok',
        }));
    };

    const trialOk = () => {
        closeTrialMessage();
    };

    const trialPay = () => {
        navigate(`/paywall`);
    };

    // on video change
    useEffect(() => {
        if (!videoId) return;

        logger.logEvent('page-load', {
            videoId: videoId,
            type: 'summary',
            theme,
        });

        if (variant === 'demo') {
            setSummaryParams((params) => ({...params, videoId, isAutoSummary: false, source: 'demo'}));
        } else if (location.state?.forceSummarizeVideoId === videoId) {
            setSummaryParams((params) => ({
                ...params,
                videoId,
                isAutoSummary: false,
                source: 'side-block-button-paywall',
            }));
        } else {
            setSummaryParams((params) => ({
                ...params,
                videoId,
                isAutoSummary: true,
                source: 'auto-summary',
            }));
        }
    }, [videoId]);

    // on language change
    useEffect(() => {
        if (languageRef.current === language) return;

        setSummaryParams((params) => ({...params, language, source: 'language-change'}));
        languageRef.current = language;
    }, [language]);

    // on selectedTab change
    useEffect(() => {
        setSummaryParams((params) => ({...params, summaryType: selectedTab}));
    }, [selectedTab]);

    // Subscribe to message events from parent window
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data?.type === 'summarize') {
                clickedSummarizeRef.current = true;

                setSummaryParams((params) => ({
                    ...params,
                    isAutoSummary: false,
                    source: 'summarize-button',
                }));
            }

            if (event.data?.type === 'language') {
                if (event.data.language) {
                    setLanguage(event.data.language);
                }
            }

            if (event.data?.type === 'embedMode') {
                console.log(`received embedMode: ${event.data.embedMode}`);
                logger.logEvent('embed-mode', {
                    embedMode: event.data.embedMode,
                });
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [videoId, language]);

    // show instant offer
    useEffect(() => {
        if (variant !== 'default') {
            return;
        }

        const isInitialBalance = balance === trialInitialAmount;
        if (isInitialBalance && showInstantOffer) {
            setUserSeenInstantOffer();

            navigate({pathname: '/paywall', state: null});
        }
    }, [balance, variant, showInstantOffer]);

    useEffect(() => {
        // authorized and on youtube page
        if (isAuthorized && variant === 'default') {
            mutate({seenDemo: true});
        }
    }, [isAuthorized, variant]);

    const toggleSelectedPart = (part) => {
        if (selectedParts.includes(part)) {
            setSelectedParts(selectedParts.filter((item) => item !== part));
        } else {
            setSelectedParts([...selectedParts, part]);
        }
    };

    const onReviewClose = (type, rating) => {
        setUserSeenReviewDialog(type, rating).catch((error) => console.error(error));
    };

    const handleSummarizeClick = () => {
        logger
            .logEvent('summarize-click', {
                videoId,
                language,
            })
            .catch((e) => {
                console.error(e);
            });

        clickedSummarizeRef.current = true;

        setSummaryParams((params) => ({
            ...params,
            isAutoSummary: false,
            source: 'side-block-button',
        }));

        if (isSummaryError) {
            refetch();
        }
    };

    const handleCommentsTldrClick = () => {
        if (isLoadingProfile) return;

        if (!activeSubscription && balance <= 0) {
            navigate(`/paywall`, {state: {takeAllFromIt: true}});
            return;
        }

        setSummaryParams((params) => ({
            ...params,
            source: 'side-block-button',
            isAutoSummary: false,
            commentsTldr: true,
        }));
    };

    let Footer = null;

    if (!error && (!data || isLoading)) {
        Footer = () => (
            <SummarizeButton
                isLoading={isLoading}
                selectedTab={selectedTab}
                handleSummarizeClick={handleSummarizeClick}
            />
        );
    } else if (isPartial) {
        if (violatedRestriction) {
            Footer = () => <SummaryRemoveLimitsBlock violatedRestriction={data?.violatedRestriction} />;
        } else {
            Footer = () => (
                <MakeFullSummaryButton
                    hasActiveSubscription={!!activeSubscription}
                    onClick={() => {
                        setSummaryParams((params) => ({
                            ...params,
                            isAutoSummary: false,
                            source: 'complete-summary',
                        }));
                    }}
                />
            );
        }
    }

    let Widget = null;

    if (showReviewDialog) {
        Widget = () => (
            <Fragment>
                <ReviewDialog
                    className="review-dialog"
                    registeredAt={extensionRegisteredAt}
                    type={reviewDialog}
                    onClose={onReviewClose}
                />
            </Fragment>
        );
    }

    return (
        <Layout className="summary-page">
            <Layout.Header>
                <Header
                    summaryLoaded={!!data}
                    onCopySummary={!isPartial && summaryData ? copySummary : null}
                    onCopyInsights={!isPartial && insightsData ? copyInsights : null}
                    onCopyTranscript={transcriptData ? copyTranscript : null}
                    changeSelectedTab={changeSelectedTab}
                    isLoading={isSummaryLoading}
                    isTrialMessageShown={!!trialMessage}
                    videoId={isPartial ? null : videoId}
                    language={language}
                />
            </Layout.Header>
            <Layout.Content
                className={cn('summary-page__content', {
                    'summary-page__content--full': !isPartial,
                    'masked-overflow__both': fadingState === 'both',
                    'masked-overflow__top': fadingState === 'top',
                    'masked-overflow__bottom': fadingState === 'bottom',
                    'masked-overflow': !!fadingState,
                })}
            >
                {!!showSourcePoll && clickedSummarizeRef.current ? (
                    <SourcePoll isLoading={isLoading} onAnswer={(answer) => sendPollAnswer(answer)} />
                ) : (
                    <>
                        {trialMessage && (
                            <TrialView
                                trialMessage={trialMessage}
                                closeTrialMessage={closeTrialMessage}
                                trialOk={trialOk}
                                trialPay={trialPay}
                            />
                        )}

                        {Widget && <Widget />}

                        {error && <SummaryErrorView error={error} />}

                        {!trialMessage && !isLoading && (
                            <Fragment>
                                {debouncedSummaryParams.summaryType === 'summary' && summaryData && (
                                    <SummaryView
                                        summary={summaryData}
                                        selectedParts={selectedParts}
                                        toggleSelectedPart={toggleSelectedPart}
                                        sendSeekMessage={sendSeekMessage}
                                        sendGotoMessage={sendGotoMessage}
                                        isRtl={isRtl}
                                    />
                                )}

                                {debouncedSummaryParams.summaryType === 'insights' && insightsData && (
                                    <InsightsView insights={insightsData} isRtl={isRtl} />
                                )}

                                {debouncedSummaryParams.summaryType === 'transcript' && transcriptData && (
                                    <TranscriptView
                                        transcript={transcriptData}
                                        isRtl={isRtl}
                                        sendSeekMessage={sendSeekMessage}
                                        sendGotoMessage={sendGotoMessage}
                                    />
                                )}

                                {debouncedSummaryParams.summaryType === 'comments' && commentsData && (
                                    <CommentsView
                                        commentsData={commentsData}
                                        isRtl={isRtl}
                                        sendSeekMessage={sendSeekMessage}
                                        sendGotoMessage={sendGotoMessage}
                                        activeSubscription={!!activeSubscription}
                                        handleSummarizeClick={handleCommentsTldrClick}
                                        videoId={videoId}
                                    />
                                )}
                            </Fragment>
                        )}
                    </>
                )}
            </Layout.Content>

            {(!showSourcePoll || !clickedSummarizeRef.current) && Footer && (
                <Layout.Footer className="summary-page__footer">
                    <Footer />
                </Layout.Footer>
            )}
        </Layout>
    );
};

export default Summary;
