import React, {useContext} from 'react';
import {ProfileContext} from '../../../contexts/profile/ProfileContext';
import {CrossIcon} from '../../../components/icons/CrossIcon';
import './trial-view.css';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';

export default function TrialView(props) {
    const {trialMessage, closeTrialMessage, trialOk, trialPay} = props;
    const {trialRestrictionViewcount, trialRestrictionDuration} = useContext(ProfileContext);

    let restrictionMessage = '';
    const formattedViewcount =
        trialRestrictionViewcount >= 1000
            ? `${Math.round(trialRestrictionViewcount / 1000)}k`
            : trialRestrictionViewcount;
    const formattedDuration =
        trialRestrictionDuration >= 3600
            ? `${Math.round(trialRestrictionDuration / 3600)} hour`
            : `${Math.round(trialRestrictionDuration / 60)} minutes`;

    if (trialRestrictionViewcount > 0 && trialRestrictionDuration > 0)
        restrictionMessage = `Free plan is limited to videos less than ${formattedDuration} long and with more than ${formattedViewcount}&nbsp;views`;
    else if (trialRestrictionViewcount > 0)
        restrictionMessage = `Free plan is limited to videos with more than ${formattedViewcount}&nbsp;views`;
    else if (trialRestrictionDuration > 0)
        restrictionMessage = `Free plan is limited to videos less than ${formattedDuration}&nbsp;long`;

    const RestrictionMessage = () => {
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: restrictionMessage,
                }}
            />
        );
    };

    return (
        <div className="trial-activate">
            <div className="trial-activate__cross-icon" onClick={closeTrialMessage}>
                <CrossIcon />
            </div>
            {trialMessage === 'trial-comments-tldr-not-allowed' && (
                <div className="trial-content">
                    <div className="trial-header">Comments TLDR is available in Eightify Pro</div>
                    <p>
                        <RestrictionMessage />
                    </p>
                </div>
            )}
            {trialMessage === 'trial-duration-too-long' && (
                <div className="trial-content">
                    <div className="trial-header">Video is longer than {formattedDuration}</div>
                    <p>
                        <RestrictionMessage />
                    </p>
                </div>
            )}
            {trialMessage === 'trial-view-count-low' && (
                <div className="trial-content">
                    <div className="trial-header">Video has less than {formattedViewcount} views</div>
                    <p>
                        <RestrictionMessage />
                    </p>
                </div>
            )}
            {trialMessage === 'trial-translations-not-allowed' && (
                <div className="trial-content">
                    <div className="trial-header">Translations are available in Eightify Pro</div>
                    <p>
                        <RestrictionMessage />
                    </p>
                </div>
            )}
            <div className="trial-ok-div">
                <SecondaryButton type="button" className="trial-ok-button" onClick={trialOk}>
                    I'm OK with limits
                </SecondaryButton>
                {(trialMessage === 'trial-duration-too-long' ||
                    trialMessage === 'trial-view-count-low' ||
                    trialMessage === 'trial-translations-not-allowed') && (
                    <PrimaryButton type="submit" className="trial-pay-button" onClick={trialPay}>
                        Remove limits
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
}
