import './Landing.css';
import '../summary/Fading.css';
import Rive from '@rive-app/react-canvas';
import cn from 'classnames';
import React, {Fragment, useState} from 'react';
import EightifyAPI from '../api/api';
import SummaryView from '../summary/components/SummaryView';
import InsightsView from '../summary/components/InsightsView';
import {useCurrentVideoId} from '../hooks/data/useCurrentVideoId';
import TabsView from '../header/components/SummaryTypeView';
import loader from '../header/images/loader.riv';
import {useQuery} from '@tanstack/react-query';
import {sendGotoMessage, sendSeekMessage} from '../summary/helpers/summary.helpers';
import {Layout} from '../components/Layout';
import {useSummaryFadingState} from '../summary/hooks/useSummaryFadingState';
import LanguagePicker from '../header/components/LanguagePicker';
import CopySummaryTextButton from '../header/components/CopySummaryButton/CopySummaryTextButton';
import CopySummaryLinkButton from '../header/components/CopySummaryButton/CopySummaryLinkButton';
import {useSummaryCopy} from '../summary/hooks/useSummaryCopy';
import {useLanguage} from '../hooks/data/useLanguage';
import CommentsView from '../summary/components/CommentsView';
import TranscriptView from '../summary/components/TranscriptView';

const Landing = () => {
    const [selectedTab, setSelectedTab] = useState('insights');
    const [selectedParts, setSelectedParts] = useState([]);
    const [language] = useLanguage();
    const videoId = useCurrentVideoId();

    const {isFetching, data, isError, error} = useQuery({
        enabled: !!videoId,
        queryKey: ['landingSummary', videoId, {type: selectedTab, language}],
        staleTime: 5 * 60 * 1000,
        queryFn: () => EightifyAPI.getLandingSummary({videoId, summaryType: selectedTab, language}),
        retry: (_, error) => error?.status !== 429,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const summaryData = selectedTab === 'summary' ? data : null;
    const insightsData = selectedTab === 'insights' ? data : null;
    const commentsData = selectedTab === 'comments' ? data : null;
    const transcriptData = selectedTab === 'transcript' ? data : null;

    const {copyInsights, copySummary} = useSummaryCopy({
        videoId,
        language,
        activeSubscription: null,
        summary: summaryData,
        insights: insightsData,
    });

    const fadingState = useSummaryFadingState({
        isPartial:
            selectedTab === 'summary'
                ? !!summaryData?.isPartial
                : selectedTab === 'insights'
                ? !!insightsData?.isPartial
                : false,
    });

    const toggleSelectedPart = (part) => {
        if (selectedParts.includes(part)) {
            setSelectedParts(selectedParts.filter((item) => item !== part));
        } else {
            setSelectedParts([...selectedParts, part]);
        }
    };

    return (
        <Layout>
            <Layout.Header>
                <div className="eightify-header" style={{padding: 0, paddingBottom: 4}}>
                    {isFetching && (
                        <div className="preparing-summary">
                            <Rive src={loader} className="rive-loader" />
                            Loading
                        </div>
                    )}

                    {!isFetching && (
                        <TabsView
                            selectedTab={selectedTab}
                            changeSelectedTab={(type) => isError || setSelectedTab(type)}
                        />
                    )}

                    <div className="eightify-header__action-bar">
                        <LanguagePicker />

                        {((selectedTab === 'summary' && summaryData) ||
                            (selectedTab === 'insights' && insightsData)) && (
                            <CopySummaryTextButton
                                selectedTab={selectedTab}
                                onCopySummary={copySummary}
                                onCopyInsights={copyInsights}
                            />
                        )}

                        {!!videoId && <CopySummaryLinkButton videoId={videoId} language={language} />}
                    </div>
                </div>
            </Layout.Header>

            <Layout.Content
                className={cn('unauthorized__summary', {
                    'masked-overflow__both': fadingState === 'both',
                    'masked-overflow__top': fadingState === 'top',
                    'masked-overflow__bottom': fadingState === 'bottom',
                    'masked-overflow': !!fadingState && !isError,
                })}
            >
                {!isFetching && (
                    <Fragment>
                        {selectedTab === 'summary' && !!summaryData && (
                            <SummaryView
                                summary={summaryData}
                                selectedParts={selectedParts}
                                toggleSelectedPart={toggleSelectedPart}
                                sendSeekMessage={sendSeekMessage}
                                sendGotoMessage={sendGotoMessage}
                            />
                        )}

                        {selectedTab === 'insights' && !!insightsData && <InsightsView insights={insightsData} />}
                        {selectedTab === 'comments' && !!commentsData && (
                            <CommentsView
                                commentsData={commentsData}
                                sendSeekMessage={sendSeekMessage}
                                sendGotoMessage={sendGotoMessage}
                                activeSubscription={true}
                                videoId={videoId}
                            />
                        )}

                        {selectedTab === 'transcript' && !!transcriptData && (
                            <TranscriptView
                                transcript={transcriptData}
                                sendSeekMessage={sendSeekMessage}
                                sendGotoMessage={sendGotoMessage}
                            />
                        )}
                    </Fragment>
                )}

                {isError &&
                    (error?.status === 429 ? (
                        <pre className="landing__error">
                            Too many requests. Install our extension for unlimited summaries.
                        </pre>
                    ) : (
                        <pre className="landing__error">
                            Something went wrong 😿 Please try again later. We are working on it.
                        </pre>
                    ))}
            </Layout.Content>
        </Layout>
    );
};

export default Landing;
