import React, {Fragment} from 'react';
import cn from 'classnames';
import '../../Summary.css';
import SummaryContainer from '../SummaryContainer';
import {formatTimestamp} from '../../../util/util';
import {ChevronDownIcon} from '../../../components/icons/ChevronDownIcon';
import {ChevronUpIcon} from '../../../components/icons/ChevronUpIcon';
import {Timestamp} from "../../../util/Timestamp";

export default function SummaryView({summary, selectedParts, toggleSelectedPart, sendSeekMessage, sendGotoMessage, isRtl = false}: {
    summary: {
        title?: string,
        isPartial?: boolean,
        // TODO: Enum
        chargeReason?: string,
        // TODO: Enum
        language: string,
        summary?: {
            emoji: string,
            startTime: Timestamp,
            tldr: string,
            keyIdeas: string[],
            subchapters: string[]
        }[]
    },
    selectedParts: number[],
    toggleSelectedPart: (part: number) => void,
    sendSeekMessage: (time: number) => void,
    sendGotoMessage: (time: number) => void,
    isRtl?: boolean,
}) {
    const {isPartial} = summary;

    return (
        <SummaryContainer>
            {summary.title && <div className={"tab-title"}>Timestamped summary</div>}
            {summary.title
                ? <div className="summary-title" dir={isRtl ? 'rtl' : 'ltr'}>{summary.title}</div>
                : <div className="summary-title">Nothing to summarize here 😔</div>
            }

            {summary.summary && summary.summary.length > 0 && (
                <div style={{position: 'relative'}} dir={isRtl ? 'rtl' : 'ltr'}>
                    <ul className="key-ideas">
                        {summary.summary.map((element, index) => (
                            <li
                                key={index}
                                className={cn({
                                    selected: selectedParts.includes(index),
                                    partial: isPartial,
                                    single: element.keyIdeas.length === 0,
                                })}
                            >
                                <span className="emoji-point">{element.emoji}</span>
                                <div className="key-left">
                                <div
                                    className="title"
                                    onClick={() => {
                                        if (!isPartial && element.keyIdeas.length > 0) {
                                            toggleSelectedPart(index);
                                        }
                                    }}
                                >
                                        <span
                                            className="timestamp"
                                            onMouseEnter={() => sendSeekMessage(element.startTime)}
                                            onMouseLeave={() => sendSeekMessage(-1)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                sendGotoMessage(element.startTime);
                                            }}
                                        >
                                            {formatTimestamp(element.startTime)}
                                        </span>{' '}
                                        <span>{element.tldr}</span>
                                        {element.keyIdeas.length > 0 && (
                                            <Fragment>
                                                {' '}
                                                <span className="toggle-hint" onClick={() => toggleSelectedPart(index)}>
                                                    {selectedParts.includes(index) ? (
                                                        <>
                                                            Collapse
                                                            <i className="toggle-hint__icon">
                                                                <ChevronUpIcon />
                                                            </i>
                                                        </>
                                                    ) : (
                                                        <>
                                                            Expand
                                                            <i className="toggle-hint__icon">
                                                                <ChevronDownIcon />
                                                            </i>
                                                        </>
                                                    )}
                                                </span>
                                            </Fragment>
                                        )}
                                    </div>
                                    {selectedParts.includes(index) && element && (
                                        <ul className="sub-ideas">
                                            {element.keyIdeas.map((element, index) => (
                                                <li key={index}>{element}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </SummaryContainer>
    );
}
