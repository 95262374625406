export default function GoogleSearchIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
            <path
                fill="currentColor"
                d="M16.125 8.188c0 4.437-3.031 7.562-7.5 7.562A7.709 7.709 0 0 1 .875 8 7.729 7.729 0 0 1 8.625.25c2.063 0 3.844.781 5.188 2.031l-2.126 2.031C8.938 1.656 3.814 3.656 3.814 8c0 2.719 2.156 4.906 4.812 4.906 3.063 0 4.219-2.187 4.375-3.344H8.625V6.906H16c.063.407.125.782.125 1.282Z"
            />
        </svg>
    );
}
