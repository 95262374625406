import {useMutation} from '@tanstack/react-query';
import {apiClient} from '../api/axiosClient';
import {debounce} from 'lodash';

type SendUnauthorizedEventParams = {
    isAuto: boolean;
    method?: string;
};

export function useUnauthorizedEvent() {
    const {mutate} = useMutation({
        mutationKey: ['auth', 'unauthorized'],
        mutationFn: async ({isAuto, method}: SendUnauthorizedEventParams) => {
            await apiClient.post('/unauthorized', {
                isAuto,
                method,
            });
        },
    });

    return debounce(mutate, 500);
}
