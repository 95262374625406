import {useNavigate} from 'react-router-dom';
import Const from '../../../const';
import MenuItem from './MenuItem';
import {Fragment, useContext} from 'react';
import {ProfileContext} from '../../../contexts/profile/ProfileContext';
import classes from './menu.module.css';
import {BoltIcon} from '../../../components/icons/BoltIcon';
import {EmailIcon} from '../../../components/icons/EmailIcon';
import {SignOutIcon} from '../../../components/icons/SignOutIcon';
import {Divider} from '../../../components/Divider';
import {ThemeContext} from '../../../contexts/theme/ThemeContext';
import EightifyAPI from '../../../api/api';
import {useUnauthorizedEvent} from '../../../unauthorized/useUnauthorizedEvent';

export default function MainMenu({id, setIsOpened}) {
    const navigate = useNavigate();
    const {logout} = useContext(ProfileContext);
    const {isSafari} = useContext(ThemeContext);
    const sendUnauthorizedEvent = useUnauthorizedEvent();

    return (
        <div id={id} className={classes.menu}>
            <MenuItem
                title="My plan"
                icon={<BoltIcon />}
                onClick={() => {
                    setIsOpened(false);
                    navigate('/paywall', {state: {takeAllFromIt: true}});
                }}
            />
            <MenuItem
                title="Email us"
                icon={<EmailIcon />}
                link={`mailto:${Const.supportEmail}`}
                onClick={() => {
                    setIsOpened(false);
                }}
            />
            {!isSafari && (
                <Fragment>
                    <Divider className={classes.menuItemDivider} />
                    <MenuItem
                        className={classes.menuItemSignOut}
                        title="Sign out"
                        icon={<SignOutIcon />}
                        onClick={() => {
                            setIsOpened(false);
                            sendUnauthorizedEvent({isAuto: false, method: '/logout'});
                            EightifyAPI.logout().catch((error) => console.error(error));
                            logout({state: {isLogout: true}});
                        }}
                    />
                </Fragment>
            )}
        </div>
    );
}
