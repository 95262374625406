import React, {useState} from 'react';
import {AvatarIcon} from "../../../components/icons/AvatarIcon";

export default function AvatarPicture(props) {
    const {className, src, alt} = props
    const [pictureLoadError, setError] = useState(false)
    return (
        pictureLoadError ? (
            <div className={className}><AvatarIcon></AvatarIcon></div>
        ) : (
            <img loading="lazy" src={src} alt={alt} className={className} onError={() => {setError(true)}}/>
        )
    );
}
