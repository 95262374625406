import {useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {LayoutContext} from '../../components/Layout/LayoutContext';

export const FRAME_MAX_HEIGHT = ['demo', 'landing'].includes(global.eightify.variant) ? Number.MAX_VALUE : 600;

const updateEightifyIFrameHeight = (height) => {
    window.parent.postMessage({type: 'height', height}, '*');
};

export function useFrameHeight() {
    const [maxHeight] = useState(FRAME_MAX_HEIGHT);
    const {contentHeight, headerHeight, footerHeight} = useContext(LayoutContext);
    const requestedHeightRef = useRef(0);
    const location = useLocation();

    useEffect(() => {
        return () => {
            requestedHeightRef.current = 0;
        };
    }, []);

    useEffect(() => {
        if (contentHeight == null || footerHeight == null || headerHeight == null) {
            return;
        }

        updateEightifyIFrameHeight(
            Math.min(maxHeight, Math.max(contentHeight + footerHeight + headerHeight, requestedHeightRef.current))
        );
    }, [contentHeight, headerHeight, footerHeight, location, maxHeight]);

    const requestFrameHeight = (value) => {
        if (contentHeight == null || footerHeight == null || headerHeight == null) {
            return;
        }

        requestedHeightRef.current = value;

        updateEightifyIFrameHeight(Math.min(maxHeight, Math.max(contentHeight + footerHeight + headerHeight, value)));
    };

    const requestContentHeight = (value) => requestFrameHeight(value + footerHeight + headerHeight);

    return {requestFrameHeight, requestContentHeight, maxHeight};
}
