export const sendGotoMessage = (startTime: number) => {
    window.parent.postMessage(
        {
            type: 'goto',
            startTime: startTime,
        },
        '*'
    );
};

export const sendSeekMessage = (startTime: number) => {
    window.parent.postMessage(
        {
            type: 'seek',
            startTime: startTime,
        },
        '*'
    );
};

export function isValidVideoId(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    return /^[A-Za-z0-9_-]{11}$/.test(value);
}
