import React, {Fragment} from 'react';
import './styles.css';
import sparkles from "../../images/sparkles.svg";

export default function SummarizeButton(props) {
    const {isLoading, handleSummarizeClick, selectedTab} = props;

    let text = isLoading ? "Preparing summary" : (selectedTab === 'comments' ? "Summarize comments" : "Summarize video")

    return (
        <div className={'summarize-button-block'}>
            <button
                className={`summarize-button ${isLoading ? 'loading' : ''}`}
                onClick={isLoading ? null : handleSummarizeClick}
            >
                {!isLoading && <img className={'summarize-button-img'} src={sparkles} alt={'sparkles'} />}
                {isLoading ? <div className="summarize-button-spinner"></div> : null}
                {text}
            </button>
        </div>
    );
}
